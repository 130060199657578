// components
import { FinancialStatusStart as FinancialStatusStartPage } from '@karehero/llama';

/**
 *  FinancialStatusStart is the page displayed for starting the financial status process.
 */
const FinancialStatusStart = () => {
  return (
    <FinancialStatusStartPage
      toFinancialInfo={'/financial-status/financial-status-profile'}
    />
  );
};

FinancialStatusStart.displayName = 'FinancialStatusStart';
export default FinancialStatusStart;
