// react
import { useCallback, useEffect } from 'react';

// components
import { ClientPortal as ClientPortalPage } from '@karehero/llama';

// store
import {
  useGetAllOrganizationAccountsQuery,
  useGetInviteEmailTemplateQuery,
  useGetSessionOrganizationQuery,
  useReinviteMemberMutation,
} from 'store/api/organization';

import { useSendInternalReferralMutation } from 'store/api/referral';
import { newToast } from 'store/slices/toastSlice';
import { getAccount } from 'store/slices/iamSlice';
import { useSelector } from 'store';

import { useDispatch } from 'react-redux';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';
import { ReferClientRequest } from '@karehero/models';

/**
 * ClientPortal shows the accounts and invited members of an organization
 */
const ClientPortal = () => {
  // hooks
  const dispatch = useDispatch();
  const { data: accounts } = useGetAllOrganizationAccountsQuery();
  const { data: organization } = useGetSessionOrganizationQuery();
  const { data: template } = useGetInviteEmailTemplateQuery();
  const [sendInternalReferral] = useSendInternalReferralMutation();
  const [reinviteMember] = useReinviteMemberMutation();

  const account = useSelector(getAccount);

  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    track(MixpanelEvent.InternalPartnerReferralFormViewed, {});
  }, [track]);

  // callbacks
  const inviteClient = useCallback(
    (formData: ReferClientRequest) => {
      sendInternalReferral(formData);
      track(MixpanelEvent.InternalPartnerReferralFormSubmitted, {});
    },
    [track, sendInternalReferral],
  );

  // methods
  const handleResendInvite = useCallback(
    async (id?: string) => {
      if (!id) return;
      const res = await reinviteMember(id);

      if ('error' in res) {
        console.error(res.error);
        return;
      }
    },
    [reinviteMember],
  );

  return (
    <ClientPortalPage
      isLoading={!accounts || !account || !organization || !template}
      accounts={accounts}
      ownerAccount={account}
      organization={organization}
      emailTemplate={template || ''}
      inviteClient={inviteClient}
      resendInvite={handleResendInvite}
      isBackButton={false}
      notifyToast={(req: { title: string; description: string }) =>
        dispatch(newToast(req))
      }
    />
  );
};

export default ClientPortal;
