// react
import { useEffect } from 'react';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

const TSB_URL = 'https://online.zenco.com/start/karehero/landing/tsb';
const DEFAULT_URL = 'https://online.zenco.com/start/karehero/landing';

/**
 * PoaRedirect is the external zenco page.
 */
const PoaRedirect = () => {
  // hooks
  const account = useSelector(getAccount);

  useEffect(() => {
    if (!account) return;

    const redirectUrl =
      account.organization?.name === 'TSB' ? TSB_URL : DEFAULT_URL;
    window.location.href = redirectUrl;
  }, [account]);

  return null;
};

export default PoaRedirect;
