// react
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

// components
import {
  Avatar,
  Button,
  Dialog,
  Input,
  ProfilePreview,
  TablePage,
} from '@karehero/llama';

// store
import {
  useDeleteAccountMutation,
  useGetAllAccountQuery,
  useMigrateAccountMutation,
  useUpdateAccountHubSpotPropertiesMutation,
} from 'store/api/account';
import { Account } from '@karehero/models';
import { newToast } from 'store/slices/toastSlice';
import { useDispatch } from 'react-redux';

// models
import { prettyKindeIdentityType } from '@karehero/models';

// tanstack
import { createColumnHelper } from '@tanstack/react-table';

// columns
const columnHelper = createColumnHelper<Account>();

const columns = [
  columnHelper.accessor('imgSrc', {
    header: '',
    cell: (info) => {
      const row = info.cell.row.original;
      const fallback =
        `${row.firstName?.[0] || ''}${row.lastName?.[0] || ''}`.toUpperCase();
      return <Avatar size='xs' fallback={fallback} />;
    },
  }),
  columnHelper.accessor('fullName', {
    header: 'Name',
  }),
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('kindeEmail', {
    header: 'Kinde Email',
    cell: (info) => {
      const row = info.cell.row.original;
      const kindeEmail = row.kindeEmail;
      if (kindeEmail) return kindeEmail;
      if (row.isMigrationEmailReceived) return 'Migration email sent';
      return 'Not migrated';
    },
  }),
  columnHelper.accessor('kindeAuthType', {
    header: 'Auth',
    cell: (info) =>
      prettyKindeIdentityType(info.cell.row.original.kindeAuthType),
  }),
  columnHelper.accessor('tier.name', {
    header: 'Tier',
  }),
  columnHelper.accessor('accountRole.name', {
    header: 'Account Role',
  }),
];

/**
 * ManageAccounts is the dashboard page.
 */
const ManageAccounts = () => {
  // state
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [selectedProfileID, setSelectedProfileID] = useState<string | null>(
    null,
  );

  // hooks
  const { data: accounts } = useGetAllAccountQuery();
  const [migrateAccount] = useMigrateAccountMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const [updateHubSpotProperties] = useUpdateAccountHubSpotPropertiesMutation();
  const dispatch = useDispatch();

  // memos
  const selectedProfile = useMemo(() => {
    if (!selectedProfileID || !accounts) return null;
    return accounts.find((account) => account.id === selectedProfileID);
  }, [selectedProfileID, accounts]);

  const rows = useMemo(() => {
    if (!accounts) return [];
    return accounts
      .filter((account) => {
        // remove care recipient accounts
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}@karehero.com$/i;
        return !uuidRegex.test(account.email);
      })
      .map((account) => ({
        ...account,
      }));
  }, [accounts]);

  const selectedProfileInitials = useMemo(() => {
    if (!selectedProfile) return '';
    return (
      (selectedProfile?.firstName?.[0] || '').toUpperCase() +
      (selectedProfile?.lastName?.[0] || '').toUpperCase()
    );
  }, [selectedProfile]);

  // methods
  const handleDeleteAccount = useCallback(
    async (id?: string) => {
      if (!id) return;
      const res = await deleteAccount({ id });
      if ('error' in res) {
        console.error(res.error);
        setIsDeleteAccountOpen(false);
        setDeleteConfirmation('');
        return;
      }
      dispatch(
        newToast({
          title: 'Account Deleted',
          description: 'Account successfully deleted',
          variant: 'success',
        }),
      );
      setIsDeleteAccountOpen(false);
      setIsProfilePreviewOpen(false);
      setDeleteConfirmation('');
    },
    [deleteAccount, dispatch],
  );

  const handleMigrateAccount = useCallback(
    async (email?: string) => {
      if (!email) return;
      await migrateAccount({
        email: email,
      });
      dispatch(
        newToast({
          title: 'Migration email sent',
          description: `An email has been sent to ${email} to migrate to Kinde.`,
        }),
      );
    },
    [migrateAccount, dispatch],
  );

  const handleUpdateAccountHubsSpotProperties = useCallback(async () => {
    if (!selectedProfile?.id) return;
    const res = await updateHubSpotProperties({
      id: selectedProfile.id,
    });
    if ('error' in res) {
      console.error(res.error);
      dispatch(
        newToast({
          title: 'Error updating HubSpot properties',
          description: `Failed to update HubSpot properties for ${selectedProfile?.email}`,
          variant: 'error',
        }),
      );
      return;
    }
    dispatch(
      newToast({
        title: 'HubSpot properties updated',
        description: `HubSpot properties updated for ${selectedProfile?.email}`,
      }),
    );
  }, [updateHubSpotProperties, selectedProfile, dispatch]);

  return (
    <>
      <TablePage
        title='Accounts'
        columns={columns}
        rows={rows}
        actions={[
          {
            label: 'View',
            onPress: (account: Account) => {
              setSelectedProfileID(account.id);
              setIsProfilePreviewOpen(true);
            },
          },
        ]}
        toolbarActions={
          <Button
            ariaLabel='new account'
            size='sm'
            to={'/admin/account/new'}
            iconLeft={'plus'}
            isRectangle
          >
            New Account
          </Button>
        }
      />
      <Dialog
        variant='sheet'
        isOpen={isProfilePreviewOpen}
        setIsOpen={setIsProfilePreviewOpen}
        zIndex={1}
      >
        <ProfilePreview
          fallback={selectedProfileInitials}
          name={`${selectedProfile?.firstName || ''} ${
            selectedProfile?.lastName || ''
          }`}
          role={selectedProfile?.accountRole.name}
          tier={selectedProfile?.tier.name}
          email={selectedProfile?.email}
          kindeEmail={selectedProfile?.kindeEmail}
          onClose={() => setIsProfilePreviewOpen(false)}
          actions={[
            {
              label: 'Delete Account',
              onPress: () => setIsDeleteAccountOpen(true),
            },
            {
              label: 'Sync HubSpot Properties',
              onPress: handleUpdateAccountHubsSpotProperties,
            },
            ...(selectedProfile?.kindeEmail
              ? []
              : [
                  {
                    label: `Migrate to Kinde${
                      selectedProfile?.isMigrationEmailReceived
                        ? ' (already migrated)'
                        : ''
                    }`,
                    onPress: () => handleMigrateAccount(selectedProfile?.email),
                  },
                ]),
          ]}
          isActionsDisabled
        />
      </Dialog>
      <Dialog
        variant='dialog'
        isOpen={isDeleteAccountOpen}
        setIsOpen={setIsDeleteAccountOpen}
        title='Are you sure you want to delete this account?'
        description={`${selectedProfile?.email}`}
        zIndex={2}
      >
        <FormWrapper>
          <Input
            label='Type the email address to confirm'
            value={deleteConfirmation}
            onChange={setDeleteConfirmation}
          />
          <Button
            ariaLabel='delete account'
            isDisabled={deleteConfirmation !== selectedProfile?.email}
            onPress={() => handleDeleteAccount(selectedProfile?.id)}
          >
            Delete Account
          </Button>
        </FormWrapper>
      </Dialog>
    </>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;

export default ManageAccounts;
