import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Editor } from "../../organisms/Editor/Editor";
// libs
import { Card, CardColor, Icon } from "../../atoms";
/**
 * CareProfileForm displays a single form from a care profile.
 */
export const CareProfileForm = ({ className, form, value, onChange, careRecipientName, isDisabled, isAiHelper, }) => {
    // memos
    const formValue = useMemo(() => {
        if (!form || !value)
            return undefined;
        return value[form.id];
    }, [form, value]);
    // callbacks
    const placeholderReplacer = useCallback((input) => {
        return input === null || input === void 0 ? void 0 : input.replace('{careRecipient}', careRecipientName || 'the care recipient');
    }, [careRecipientName]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsxs(Header, { children: [_jsx(Title, { children: placeholderReplacer(form === null || form === void 0 ? void 0 : form.title) }), (form === null || form === void 0 ? void 0 : form.description) && (_jsx(InfoCard, Object.assign({ color: 'yellow', isBordered: false, elevation: 0 }, { children: _jsxs(InfoCardDetails, { children: [_jsx(StyledIcon, { icon: 'info-circle', fontSize: 18 }), _jsx(TextWrapper, { children: _jsx(InfoCardContent, { children: placeholderReplacer(form.description) }) })] }) })))] }), form && value && (_jsx(Editor, { fields: form.fields, isActionsDisabled: true, isFloatingActions: false, value: formValue, onChange: (val) => onChange === null || onChange === void 0 ? void 0 : onChange(Object.assign(Object.assign({}, value), { [form.id]: val })), isDisabled: !isDisabled })), isAiHelper && (_jsx(CardColor, Object.assign({ color: 'blue', isBordered: false }, { children: _jsxs(StyledLabel, { children: [_jsx("strong", { children: "Need Support?" }), _jsx("div", { children: "Ask any question using our AI chatbot, or schedule a call with your care expert." })] }) }), 'card-content'))] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  margin-top: ${(props) => props.theme.spacing[32]};
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledLabel = styled.label `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  display: flex;
  flex-direction: column;
`;
const InfoCard = styled(Card) `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  padding: ${(props) => props.theme.spacing[16]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const InfoCardDetails = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  padding-left: ${(props) => props.theme.spacing[8]};
`;
const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const InfoCardContent = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const StyledIcon = styled(Icon) `
  padding-top: ${(props) => props.theme.spacing[4]};
`;
CareProfileForm.displayName = 'CareProfileForm';
export default CareProfileForm;
