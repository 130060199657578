import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// radix
import * as RadixSlider from '@radix-ui/react-slider';
// components
import { BaseField } from "..";
/**
 * Slider allows the user to select a value from a range.
 */
export const Slider = ({ id, label, description, tooltip, isRequired, min = 0, max = 100, step = 1, isDisabled = false, isSegmented = false, isHideTitle = false, segmentLabels, lowLabel = 'Low', highLabel = 'High', options, value, onChange, }) => {
    var _a, _b;
    // memos
    const computedOptions = useMemo(() => options
        ? options.sort((a, b) => ((a === null || a === void 0 ? void 0 : a.order) || 0) - ((b === null || b === void 0 ? void 0 : b.order) || 0))
        : undefined, [options]);
    const computedMin = useMemo(() => (computedOptions ? 0 : min), [min, computedOptions]);
    const computedMax = useMemo(() => (computedOptions ? computedOptions.length - 1 : max), [max, computedOptions]);
    const computedStep = useMemo(() => (computedOptions ? 1 : step), [step, computedOptions]);
    const computedValue = useMemo(() => {
        if (computedOptions && value !== undefined) {
            return computedOptions.findIndex((o) => o.value === value);
        }
        if (!computedOptions) {
            return Math.min(Math.max(value, computedMin), computedMax);
        }
        return undefined;
    }, [value, computedOptions, computedMin, computedMax]);
    const segments = useMemo(() => {
        if (!isSegmented)
            return undefined;
        const segments = [];
        for (let i = computedMin; i <= computedMax; i += computedStep) {
            const left = ((i - computedMin) / (computedMax - computedMin)) * 100;
            segments.push(_jsx(Segment, { "$left": left, "$isOn": !!computedValue && computedValue >= i * computedStep }, i));
        }
        return segments;
    }, [isSegmented, computedMin, computedMax, computedStep, computedValue]);
    useEffect(() => {
        var _a;
        if (!computedOptions && !value)
            onChange === null || onChange === void 0 ? void 0 : onChange(min);
        if (computedOptions && !value)
            onChange === null || onChange === void 0 ? void 0 : onChange((_a = computedOptions[0]) === null || _a === void 0 ? void 0 : _a.value);
    }, [computedOptions, value, min, onChange]);
    return (_jsxs(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: [computedOptions && (_jsx(SegmentLabel, { children: typeof value === 'string'
                    ? (_a = computedOptions.find((o) => o.value === value)) === null || _a === void 0 ? void 0 : _a.label
                    : (_b = computedOptions[0]) === null || _b === void 0 ? void 0 : _b.label })), _jsxs(StyledSliderRoot, Object.assign({ value: computedValue ? [computedValue] : [0], onValueChange: (val) => {
                    var _a;
                    const selectedValue = computedOptions
                        ? (_a = computedOptions[val[0]]) === null || _a === void 0 ? void 0 : _a.value
                        : val[0];
                    onChange === null || onChange === void 0 ? void 0 : onChange(selectedValue);
                }, min: computedMin, max: computedMax, step: computedStep, disabled: isDisabled, "$isLabelled": !!segmentLabels, "aria-label": 'slider' }, { children: [_jsx(StyledSliderTrack, Object.assign({ "$isDisabled": isDisabled }, { children: _jsx(StyledSliderRange, { "$isDisabled": isDisabled }) })), _jsx(SegmentWrapper, { children: segments }), _jsx(StyledSliderThumb, Object.assign({ "$isDisabled": isDisabled }, { children: _jsx(StyledSliderThumbInner, { "$isDisabled": isDisabled }) })), !segments && _jsx(Value, { children: computedValue })] })), _jsxs(SegmentFooter, { children: [_jsx("div", { children: lowLabel }), _jsx("div", { children: highLabel })] })] })));
};
const StyledSliderRoot = styled(RadixSlider.Root) `
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  height: 44px;
`;
const StyledSliderTrack = styled(RadixSlider.Track) `
  background: ${(props) => props.theme.color.neutral[40]};
  margin: 0 22px;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 10px;

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[40]};
    `}
`;
const StyledSliderRange = styled(RadixSlider.Range) `
  position: absolute;
  background: ${(props) => props.theme.color.primary[40]};
  border-radius: 9999px;
  height: 100%;

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[50]};
    `}
`;
const StyledSliderThumb = styled(RadixSlider.Thumb) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: ${(props) => props.theme.color.primary[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  border-radius: 9999px;

  &:hover {
    outline: 4px solid ${(props) => props.theme.color.primary[20]};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.color.primary[50]};
    outline: 4px solid ${(props) => props.theme.color.primary[20]};
  }

  ${(props) => props.$isDisabled &&
    css `
      pointer-events: none;
    `}
`;
const StyledSliderThumbInner = styled.div `
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  background: ${(props) => props.theme.color.primary[40]};
  border-radius: 9999px;
  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[50]};
    `}
`;
const SegmentWrapper = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 0;
  height: 100%;
  margin: 0 22px;
  box-sizing: border-box;
`;
const Segment = styled.div `
  position: absolute;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 99999px;
  left: ${(props) => props.$left}%;
  transform: translateX(-50%);
  background: ${(props) => props.$isOn
    ? props.theme.color.primary[30]
    : props.theme.color.neutral[20]};
  box-shadow: ${(props) => props.theme.elevation[1]};
`;
const SegmentLabel = styled.div `
  text-align: center;
  font: ${(props) => props.theme.typography.body.md[props.$isStrong ? 'semibold' : 'regular']};
  color: ${(props) => props.theme.color.neutral[80]};
  left: ${(props) => props.$left}%;
  margin-top: ${(props) => props.theme.spacing[4]};
  height: 32px;
  min-height: 32px;
`;
const SegmentFooter = styled.div `
  display: flex;
  justify-content: space-between;
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-top: ${(props) => props.theme.spacing[2]};
`;
const Value = styled.div `
  position: absolute;
  top: -${(props) => props.theme.spacing[2]};
  right: 0;
  transform: translateY(-100%);
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: right;
`;
Slider.displayName = 'Slider';
export default Slider;
