var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
// react
import { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { Boolean } from "../../atoms/Boolean/Boolean";
import { Input } from "../../atoms/Input/Input";
import { TextArea } from "../../atoms/TextArea/TextArea";
import { Select } from "../../atoms/Select/Select";
import { Date as DatePicker } from "../../atoms/Date/Date";
import { MultiSelect } from "../../atoms/MultiSelect/MultiSelect";
import { MultiSelectChip } from "../../atoms/MultiSelectChip/MultiSelectChip";
import { MultiSelectBox } from "../../atoms/MultiSelectBox/MultiSelectBox";
import { Slider } from "../../atoms/Slider/Slider";
import { RadioGroup, } from "../../atoms/RadioGroup/RadioGroup";
import { BooleanTable } from "../../atoms/BooleanTable/BooleanTable";
import { TimePicker } from "../../atoms/TimePicker/TimePicker";
import { ToggleSelect } from "../../atoms";
import { ContactField } from "../../molecules";
import { AddressField } from "../../molecules";
import { Switch } from "../../atoms/Switch/Switch";
import { InviteCode } from "../../molecules/InviteCode/InviteCode";
import { CareCircleMemberSelector } from "../../molecules";
import { FieldType, } from "./fieldHelper";
import { validationAsString, validationStringToMethod, } from "../Editor/Editor";
import { QuestionTable } from "../QuestionTable/QuestionTable";
// hooks
import { useValidator } from "../../../hooks";
/**
 * EditorFields is the fields of the editor.
 */
export const EditorFields = memo(({ fields, value, onChange, isDisabled = false, isRow = false, isFullWidth = true, setIsValid, }) => {
    // hooks
    const { createSetIsValid } = useValidator(setIsValid);
    // convert fields into an array ordered by the order property
    const orderedFields = useMemo(() => {
        let orderedFields = [];
        Object.keys(fields).forEach((fieldKey, i) => {
            const fieldValue = fields[fieldKey];
            const index = (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.order) ? fieldValue.order : 99999 + i;
            orderedFields[index] = {
                fieldValue: fieldValue,
                fieldKey: fieldKey,
            };
        });
        return orderedFields;
    }, [fields]);
    const orderedFieldsComputed = useMemo(() => orderedFields.map((orderedField) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        const fieldValue = orderedField.fieldValue;
        const fieldKey = orderedField.fieldKey;
        if (!fieldValue)
            return null;
        let validation = [fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.validation];
        if (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.validationRules) {
            validation.push(validationStringToMethod(validationAsString(fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.validationRules)));
        }
        const validationMethod = (value) => {
            let isValid = validation.every((v) => v === undefined ? true : v === null || v === void 0 ? void 0 : v(value));
            if (isValid === undefined)
                isValid = true;
            if ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.isRequired) &&
                (value === undefined || value === null || value === '')) {
                isValid = false;
            }
            createSetIsValid(fieldKey)(isValid);
            return isValid;
        };
        const genericProps = {
            id: fieldKey,
            ariaLabel: (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.ariaLabel) || (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.label),
            placeholder: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.placeholder,
            label: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.label,
            description: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.description,
            isHideTitle: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.isHideTitle,
            isDisabled: isDisabled,
            tooltip: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.tooltip,
            isRequired: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.isRequired,
            value: (value === null || value === void 0 ? void 0 : value[fieldKey]) !== undefined ? value === null || value === void 0 ? void 0 : value[fieldKey] : '',
            validate: validationMethod,
            autoFocus: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field.autoFocus,
            onChange: (newValue) => {
                onChange(Object.assign(Object.assign({}, value), { [fieldKey]: newValue }), fieldKey);
            },
        };
        switch (fieldValue.field.type) {
            case FieldType.Text:
            case FieldType.Time:
                return _createElement(Input, Object.assign({}, genericProps, { key: fieldKey }));
            case FieldType.TextArea:
                return _createElement(TextArea, Object.assign({}, genericProps, { key: fieldKey }));
            case FieldType.Number:
                return _createElement(Input, Object.assign({}, genericProps, { type: 'number', key: fieldKey }));
            case FieldType.Email:
                return _createElement(Input, Object.assign({}, genericProps, { type: 'email', key: fieldKey }));
            case FieldType.Password:
                return _createElement(Input, Object.assign({}, genericProps, { type: 'password', key: fieldKey }));
            case FieldType.Select:
            case FieldType.SelectCustom:
                return (_createElement(Select, Object.assign({}, genericProps, { options: (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field.options) || [], key: fieldKey })));
            case FieldType.MultiSelect:
            case FieldType.MultiSelectCustom:
                return (_createElement(MultiSelect, Object.assign({}, genericProps, { isCustom: fieldValue.field.isCustom || false, options: fieldValue.field.options || [], key: fieldKey })));
            case FieldType.MultiSelectChip:
                return (_createElement(MultiSelectChip, Object.assign({}, genericProps, { isCustom: fieldValue.field.isCustom || false, options: fieldValue.field.options || [], key: fieldKey })));
            case FieldType.MultiSelectBox:
                const filteredProps = __rest(genericProps, []);
                return (_createElement(MultiSelectBox, Object.assign({}, filteredProps, { variant: fieldValue.field.variant, options: fieldValue.field.options || [], maxColumns: fieldValue.field.maxColumns || 1, isCustom: fieldValue.field.isCustom || false, size: fieldValue.field.size || 'sm', key: fieldKey })));
            case FieldType.RadioGroup:
                return (_createElement(RadioGroup, Object.assign({}, genericProps, { variant: fieldValue.field.variant, options: fieldValue.field.options || [], key: fieldKey })));
            case FieldType.BooleanTable:
                return (_createElement(BooleanTable, Object.assign({}, genericProps, { title: (_a = fieldValue.field.table) === null || _a === void 0 ? void 0 : _a.title, columns: ((_b = fieldValue.field.table) === null || _b === void 0 ? void 0 : _b.columns) || [], rows: ((_c = fieldValue.field.table) === null || _c === void 0 ? void 0 : _c.rows) || [], key: fieldKey })));
            case FieldType.QuestionTable:
                return (_createElement(QuestionTable, Object.assign({}, genericProps, { value: value, onChange: genericProps.onChange, fieldValue: orderedField.fieldValue, fieldKey: fieldKey, key: fieldKey })));
            case FieldType.Boolean:
                return _createElement(Boolean, Object.assign({}, genericProps, { key: fieldKey }));
            case FieldType.Switch:
                return _createElement(Switch, Object.assign({}, genericProps, { size: 'sm', key: fieldKey }));
            case FieldType.DatePicker:
            case FieldType.DateRange:
                return _createElement(DatePicker, Object.assign({}, genericProps, { key: fieldKey }));
            case FieldType.Contact:
                return _createElement(ContactField, Object.assign({}, genericProps, { key: fieldKey }));
            case FieldType.Address:
                return _createElement(AddressField, Object.assign({}, genericProps, { key: fieldKey }));
            case FieldType.Slider:
                return (_createElement(Slider, Object.assign({}, genericProps, { min: ((_e = (_d = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _d === void 0 ? void 0 : _d.limits) === null || _e === void 0 ? void 0 : _e.min) || 0, max: ((_g = (_f = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _f === void 0 ? void 0 : _f.limits) === null || _g === void 0 ? void 0 : _g.max) || 10, lowLabel: (_j = (_h = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _h === void 0 ? void 0 : _h.labels) === null || _j === void 0 ? void 0 : _j.low, highLabel: (_l = (_k = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _k === void 0 ? void 0 : _k.labels) === null || _l === void 0 ? void 0 : _l.high, key: fieldKey })));
            case FieldType.SliderSelect:
                return (_createElement(Slider, Object.assign({}, genericProps, { isSegmented: true, options: fieldValue.field.options || [], lowLabel: (_o = (_m = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _m === void 0 ? void 0 : _m.labels) === null || _o === void 0 ? void 0 : _o.low, highLabel: (_q = (_p = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _p === void 0 ? void 0 : _p.labels) === null || _q === void 0 ? void 0 : _q.high, key: fieldKey })));
            case FieldType.SliderSeverity:
                return (_createElement(Slider, Object.assign({}, genericProps, { min: 1, max: 5, isSegmented: true, segmentLabels: [
                        'None',
                        'Mild',
                        'Moderate',
                        'Significant',
                        'Critical',
                    ], lowLabel: (_s = (_r = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _r === void 0 ? void 0 : _r.labels) === null || _s === void 0 ? void 0 : _s.low, highLabel: (_u = (_t = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field) === null || _t === void 0 ? void 0 : _t.labels) === null || _u === void 0 ? void 0 : _u.high, key: fieldKey })));
            case FieldType.CareCircleMemberSelector:
                return (_createElement(CareCircleMemberSelector, Object.assign({}, genericProps, { careCircle: value, relationshipOptions: fieldValue.field.options || [], onInvite: fieldValue.onInvite, key: fieldKey })));
            case FieldType.TimePicker:
                return (_createElement(TimePicker, Object.assign({}, genericProps, { options: fieldValue.field.options || [], key: fieldKey })));
            case FieldType.ToggleSelect:
                return (_createElement(ToggleSelect, Object.assign({}, genericProps, { options: fieldValue.field.options || [], key: fieldKey })));
            case FieldType.InviteCode:
                return (_createElement(InviteCode, Object.assign({}, genericProps, { length: fieldValue.field.length || 6, regex: fieldValue.field.regex || /^[a-zA-Z0-9 ]$/, key: fieldKey })));
            default:
                return null;
        }
    }), [orderedFields, fields, value, onChange, isDisabled]);
    return (_jsx(StyledEditorFields, Object.assign({ "$isRow": isRow, "$isFullWidth": isFullWidth }, { children: orderedFieldsComputed })));
});
const StyledEditorFields = styled.div `
  display: flex;
  flex-direction: column;
  ${(props) => props.$isRow &&
    css `
      flex-direction: row;
    `}
  gap: ${(props) => props.theme.spacing[32]};
  ${(props) => props.$isFullWidth &&
    css `
      width: 100%;
    `};
`;
EditorFields.displayName = 'EditorFields';
export default EditorFields;
