var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
/**
 * HubSpotChat shows the HubSpot chat widget.
 */
export const HubSpotChat = ({ kindeEmail, token }) => {
    // refs
    const initialLoadRef = useRef(true);
    // memos
    const hubspotTrackingURL = useMemo(() => {
        var _a, _b;
        const env = (_a = process.env.REACT_APP_BUILD_ENVIRONMENT) !== null && _a !== void 0 ? _a : 'local';
        const urls = {
            local: 'https://js-eu1.hs-scripts.com/142770276.js',
            development: 'https://js-eu1.hs-scripts.com/142770276.js',
            staging: 'https://js-eu1.hs-scripts.com/142770276.js',
            production: 'https://js-eu1.hs-scripts.com/139578958.js',
        };
        return (_b = urls[env]) !== null && _b !== void 0 ? _b : urls['local'];
    }, []);
    // methods
    const loadHubSpot = useCallback(() => {
        const scriptElement = document.createElement('script');
        scriptElement.src = hubspotTrackingURL !== null && hubspotTrackingURL !== void 0 ? hubspotTrackingURL : '';
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.addEventListener('load', () => {
            window.hsConversationsSettings = Object.assign(Object.assign({}, window.hsConversationsSettings), { identificationEmail: kindeEmail, identificationToken: token, inlineEmbedSelector: '#hubspot-chat', loadImmediately: true });
            initialLoadRef.current = false;
        });
        scriptElement.addEventListener('error', () => {
            console.error('Error loading HubSpot script');
        });
        document.body.appendChild(scriptElement);
        return scriptElement;
    }, []);
    const refreshChatToken = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        window.hsConversationsSettings = Object.assign(Object.assign({}, window.hsConversationsSettings), { identificationToken: token });
        (_b = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b.remove();
        window.HubSpotConversations.widget.load();
    }), [token]);
    // effects
    useEffect(() => {
        var _a, _b;
        const scriptElement = loadHubSpot();
        (_b = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b.load();
        return () => {
            var _a, _b;
            (_b = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b.remove();
            scriptElement.remove();
        };
    }, []);
    useEffect(() => {
        if (!initialLoadRef.current)
            refreshChatToken();
    }, [token, refreshChatToken]);
    const Global = createGlobalStyle ``;
    return (_jsxs(_Fragment, { children: [_jsx(Global, {}), _jsx(Wrapper, { id: 'hubspot-chat' })] }));
};
const Wrapper = styled.div `
  width: 100%;
  height: 100%;

  & #hubspot-conversations-inline-parent,
  & iframe {
    border: none;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
  }
`;
HubSpotChat.displayName = 'HubSpotChat';
export default HubSpotChat;
