import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { Icon } from "../../atoms/Icon/Icon";
// theme
import { theme } from "../../../theme";
import { Label } from "../../atoms";
export const ShortlistProgress = ({ shortlistItems, }) => {
    // memos
    const sortedshortlistItems = useMemo(() => {
        const shortlistItemsCopy = shortlistItems === null || shortlistItems === void 0 ? void 0 : shortlistItems.slice();
        return shortlistItemsCopy === null || shortlistItemsCopy === void 0 ? void 0 : shortlistItemsCopy.sort((a, b) => a.order - b.order);
    }, [shortlistItems]);
    return (_jsxs(Wrapper, { children: [_jsx(Connector, {}), sortedshortlistItems === null || sortedshortlistItems === void 0 ? void 0 : sortedshortlistItems.map((item) => (_jsxs(ActionRow, { children: [_jsx(IconWrapper, { children: _jsx(StyledIcon, { icon: 'check', fontSize: 16, fill: item.isComplete
                                ? theme.color.success[40]
                                : theme.color.neutral[30], "$color": !item.isComplete ? theme.color.neutral[10] : undefined }) }), _jsx(Containier, { children: _jsx(StyledLabel, { id: item.title, text: item.title }) })] }, item.id)))] }));
};
const StyledIcon = styled(Icon) `
  ${(props) => props.$color &&
    css `
      color: ${props.$color};
    `}
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Connector = styled.div `
  height: 78%;
  width: 3rem;
  position: absolute;
  top: 11%;
  left: 16px;
  border-left: 2px dashed ${theme.color.neutral[50]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${theme.spacing[8]};
`;
const Containier = styled.div `
  width: 100%;
  display: flex;
  padding: ${(props) => props.theme.spacing[16]} 0;
  align-content: center;
`;
const ActionRow = styled.div `
  gap: ${(props) => props.theme.spacing[12]};
  display: flex;
`;
const StyledLabel = styled(Label) `
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[90]};
`;
ShortlistProgress.displayName = 'ShortlistProgress';
export default ShortlistProgress;
