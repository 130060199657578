import PropTypes from 'prop-types';

import SearchField from './SearchField';
import CareTypePicker from './CareTypePicker';
import SearchButton from './SearchButton';

import Heading from '../../components/primitives/typography/Heading';
import Paragraph from '../../components/primitives/typography/Paragraph';
import Column from '../../components/primitives/Column';
import Row from '../../components/primitives/Row';
import Spacer from '../../components/primitives/Spacer';

export default function Search({ onSearchClick }) {
  return (
    <Column>
      <Heading>Find adult care in your area</Heading>
      <Spacer top bottom>
        <Paragraph>
          Search for care homes or care-at-home agencies in your area.
        </Paragraph>
      </Spacer>
      <Spacer bottom top>
        <CareTypePicker />
      </Spacer>
      <Spacer>
        <Row>
          <SearchField grow />
          <Spacer left>
            <SearchButton onSearchClick={onSearchClick} />
          </Spacer>
        </Row>
      </Spacer>
    </Column>
  );
}

Search.propTypes = {
  onSearchClick: PropTypes.func,
};

Search.defaultProps = {
  onSearchClick: () => {},
};
