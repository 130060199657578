// react
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// hooks
import { useCareProfile } from 'hooks';

// pdf
import { careProfileDocDefinition } from 'pdf';

// components
import {
  CareProfileHero as CareProfileHeroPage,
  CareProfileRoot,
} from '@karehero/llama';

/**
 * CareProfileStart is the dashboard page.
 */
const CareProfileStart = () => {
  // hooks
  const {
    numCompleted,
    numTotal,
    careCircle,
    answers,
    careProfileCategories,
    sections,
  } = useCareProfile();
  const navigate = useNavigate();

  // memos
  const careRecipientName = useMemo(() => {
    return `${careCircle?.careRecipientAccount?.firstName || ''} ${
      careCircle?.careRecipientAccount?.lastName || ''
    }`;
  }, [careCircle]);

  // methods
  const handlePrintPdf = useCallback(() => {
    if (!careProfileCategories) return;
    careProfileDocDefinition(
      careProfileCategories,
      answers,
      careRecipientName,
    ).open();
  }, [careProfileCategories, answers, careRecipientName]);

  return (
    <StyledCareProfileRoot
      nextLabel='Create care assessment'
      onPrintPdf={handlePrintPdf}
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      toExit='/care-profile?tab=2'
      onNext={() => navigate(`/care-profile/general-information`)}
      toPrevious='/care-profile?tab=2'
    >
      <CareProfileHeroPage />
    </StyledCareProfileRoot>
  );
};

const StyledCareProfileRoot = styled(CareProfileRoot)`
  overflow: hidden;
`;

export default CareProfileStart;
