// react
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useCareProfile } from 'hooks';
import { useSubmitCareProfileFormMutation } from 'store/api/careProfile';

// components
import { CareProfileRoot, SuccessContent } from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * AttendanceAllowanceComplete is the dashboard page.
 */
const AttendanceAllowanceComplete = () => {
  // hooks
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const { numCompleted, numTotal, careCircle, sections, forms } =
    useCareProfile({
      categoryID: 'attendance-allowance',
    });
  const [submitCareProfileForm] = useSubmitCareProfileFormMutation();

  // memos
  const toPrevious = useMemo(() => {
    if (!forms || forms.length === 0) return '/care-profile';
    return `/attendance-allowance/${forms[forms.length - 1].id}`;
  }, [forms]);

  // methods
  const handleNext = useCallback(() => {
    track(MixpanelEvent.AttendanceAllowanceSubmitted, {});
    careCircle?.careProfile?.id &&
      submitCareProfileForm(careCircle?.careProfile?.id);
    navigate(`/attendance-allowance/success`);
  }, [track, navigate, careCircle, submitCareProfileForm]);

  return (
    <CareProfileRoot
      backLabel='Back'
      nextLabel='Submit form'
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      title={`${
        careCircle?.careRecipientAccount?.firstName || 'Your Loved One'
      }'s Attendance Allowance`}
      toExit='/care-profile?tab=3'
      onNext={handleNext}
      toPrevious={toPrevious}
      isFormCompleted={numTotal === numCompleted}
    >
      <SuccessContent
        header='Attendance Allowance Ready For Review'
        cardHeader='Your next steps'
        fixtures={[
          {
            icon: 'check-circle',
            text: 'Review your Attendance Allowance answers to ensure all the needs captured are correct',
            iconColor: 'success' as 'success',
          },
          {
            icon: 'user',
            text: 'Once submitted you can chat to your Care Expert regarding your Attendance Allowance',
            iconColor: 'blue' as 'blue',
          },
        ]}
      />
    </CareProfileRoot>
  );
};

export default AttendanceAllowanceComplete;
