import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Keni } from "../../atoms";
/**
 * CareProfileHero introduces the care plan.
 */
export const CareProfileHero = ({ className }) => {
    return (_jsxs(Content, { children: [_jsx(Title, { children: "You are about to start creating your personalised care assessment. As you go through you might feel there are a lot of questions to answer." }), _jsx(Body, { children: "Try to answer as best as you can. The more information that you can provide us with, the better your care assessment will be and the easier your care journey will become." }), _jsx(Body, { children: "We estimate the care assessment will take around 30 minutes to complete. If you feel you would benefit from our care experts' support, don't hesitate to get in touch." }), _jsx(StyledKeni, {})] }));
};
const Content = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Body = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const StyledKeni = styled(Keni) `
  max-width: 300px;
`;
CareProfileHero.displayName = 'CareProfileHero';
export default CareProfileHero;
