import api from '.';
import {
  CareProfileAnswer,
  CareProfileCategory,
  CareProfileQuestion,
  CareProfileAnswerRequest,
} from '@karehero/models';

export const careProfileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategory: builder.query<CareProfileCategory[], void>({
      query: () => '/care-profile/category',
      providesTags: ['CareProfileCategories'],
      keepUnusedDataFor: 0,
    }),
    getQuestions: builder.query<CareProfileQuestion[], string[]>({
      query: (body) => ({
        url: '/care-profile/question',
        method: 'POST',
        body: { questionIds: body },
      }),
      providesTags: ['CareProfileQuestions'],
      keepUnusedDataFor: 0,
    }),
    getAllAnswer: builder.query<{ [key: string]: CareProfileAnswer }, string>({
      query: (id) => {
        if (!id) {
          throw new Error('CareProfile id is required');
        }
        return { url: `care-profile/${id}/answer` };
      },
      providesTags: ['CareProfileAnswers'],
      keepUnusedDataFor: 0,
    }),
    createManyAnswer: builder.mutation<
      CareProfileAnswer[],
      CareProfileAnswerRequest
    >({
      query: (body: CareProfileAnswerRequest) => ({
        url: `care-profile/${body.careProfileId}/answer`,
        method: 'POST',
        body: body.answers,
      }),
      invalidatesTags: ['CareProfileAnswers', 'CareCircles'],
    }),
    submitCareProfileForm: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `care-profile/${id}/submit`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetAllCategoryQuery,
  useLazyGetAllCategoryQuery,
  useGetAllAnswerQuery,
  useCreateManyAnswerMutation,
  useLazyGetQuestionsQuery,
  useSubmitCareProfileFormMutation,
} = careProfileApi;

export default careProfileApi;
