export var KindeIdentityType;
(function (KindeIdentityType) {
    KindeIdentityType["Email"] = "email";
    KindeIdentityType["Google"] = "oauth2:google";
    KindeIdentityType["Facebook"] = "oauth2:facebook";
    KindeIdentityType["Apple"] = "oauth2:apple";
})(KindeIdentityType || (KindeIdentityType = {}));
export const prettyKindeIdentityType = (type) => {
    switch (type) {
        case KindeIdentityType.Email:
            return 'Email';
        case KindeIdentityType.Google:
            return 'Google';
        case KindeIdentityType.Facebook:
            return 'Facebook';
        case KindeIdentityType.Apple:
            return 'Apple';
        default:
            return 'None';
    }
};
