// reacrt
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// components
import { ActionPlanCategory as ActionPlanCategoryPage } from '@karehero/llama';

// hooks
import { useActionPlan } from 'hooks/useActionPlan';

// models
import { ActionPlanItem } from '@karehero/models';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * ActionPlanAll shows all action plan items.
 */
const ActionPlanCategory = () => {
  // hooks
  const { actionPlanCategories } = useActionPlan();
  const { actionPlanCategoryId } = useParams();
  const flags = useFlags();

  // memos
  const actionPlanCategory = useMemo(
    () =>
      actionPlanCategories?.find(
        (category) => category.id === actionPlanCategoryId,
      ),
    [actionPlanCategories, actionPlanCategoryId],
  );

  // NOTE: If the `financialStatus` flag is not enabled, it updates a specific action plan item  with a new route URL. This is a temporary measure and will be removed once the financial status is rolled out to all the users
  if (actionPlanCategory && !flags.financialStatus) {
    actionPlanCategory.actionPlanItems = actionPlanCategory.actionPlanItems.map(
      (action: ActionPlanItem) => {
        if (action.id === 'tell-us-about-your-loved-one-attendance-allowance') {
          return {
            ...action,
            route:
              'https://share-eu1.hsforms.com/1UrPUPKQhQyy4FuCC5Y3vvg2b3nta',
          };
        }
        return action;
      },
    );
  }

  return <ActionPlanCategoryPage actionPlanCategory={actionPlanCategory} />;
};

export default ActionPlanCategory;
