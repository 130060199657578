export const financialInfoPageItems = [
    {
        icon: 'file-contract',
        text: 'Fill in the financial status for the person requiring care',
        iconColor: 'blue',
    },
    {
        icon: 'check-circle',
        text: 'Our experts will review and calculate your bespoke funding options',
        iconColor: 'success',
    },
    {
        icon: 'home',
        text: 'We’ll send you a My Funding Guide and help you apply for funding/benefits, if relevant',
        iconColor: 'primary',
    },
];
export const financialInfoCardDetails = {
    title: 'Did you know?',
    content: 'We’ve helped thousands of families claim funding they didn’t realise they were eligible for',
    icon: 'info-circle',
    iconColor: 'yellow',
};
