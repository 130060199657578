import api from '.';
import { Account, InvitedMember } from '@karehero/models';

export const invitedMemberApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvitedMember: builder.query<InvitedMember[], void>({
      query: () => '/invite',
      providesTags: ['InvitedMembers'],
    }),
    createInvitedMemberAccount: builder.mutation<
      Account,
      { id: string; kindeEmail: string }
    >({
      query: ({ id, kindeEmail }) => ({
        url: `/invite/${id}/create`,
        method: 'POST',
        body: { kindeEmail: kindeEmail },
      }),
      invalidatesTags: ['InvitedMembers'],
    }),
  }),
});

export const {
  useGetAllInvitedMemberQuery,
  useCreateInvitedMemberAccountMutation,
} = invitedMemberApi;

export default invitedMemberApi;
