// react
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// components
import { FieldType, validationStringToMethod } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { useGetCareCircleQuery } from 'store/api/careCircle';
import {
  useCreateManyAnswerMutation,
  useGetAllAnswerQuery,
  useGetAllCategoryQuery,
} from 'store/api/careProfile';
import { getCurrentCareCircleId } from 'store/slices/careCircleSlice';

// models
import {
  CareProfileAnswer,
  CareProfileForm,
  CareProfileQuestion,
  CareProfileSubCategory,
  Element,
  Form,
  isSubQuestionRelevant,
  listAllQuestionsOfSubCategory,
  Question,
  questionArrayToMap,
  Section,
} from '@karehero/models';

interface UseCareProfileOptions {
  categoryID?: string;
}

export const useCareProfile = (options?: UseCareProfileOptions) => {
  // options
  const { categoryID } = options || {};

  // state
  const [careProfileSubCategories, setCareProfileSubCategories] = useState<
    CareProfileSubCategory[]
  >([]);
  const [answers, setAnswers] = useState<any>({});

  // hooks
  const { careProfileFormId } = useParams();
  const careCircleId = useSelector(getCurrentCareCircleId);
  const { data: careCircle } = useGetCareCircleQuery(careCircleId, {
    skip: !careCircleId,
  });

  const careProfileID = careCircle?.careProfile?.id || '';

  const { data: careProfileAnswersObj } = useGetAllAnswerQuery(
    careProfileID || '',
    { skip: careProfileID === '' },
  );
  const careProfileAnswers = useMemo(
    () => careProfileAnswersObj && Object.values(careProfileAnswersObj),
    [careProfileAnswersObj],
  );

  const { data: careProfileCategories } = useGetAllCategoryQuery();
  const [createManyAnswer] = useCreateManyAnswerMutation();

  // memos
  const category = useMemo(() => {
    return careProfileCategories?.find(
      (category) => category.id === (categoryID || 'care-plan'),
    );
  }, [careProfileCategories, categoryID]);

  const subQuestions = useMemo(() => {
    setCareProfileSubCategories(category?.careProfileSubCategories || []);

    return (category?.careProfileSubCategories || [])?.reduce(
      (acc: CareProfileQuestion[], subCategory: CareProfileSubCategory) => {
        return [...acc, ...listAllQuestionsOfSubCategory(subCategory)];
      },
      [],
    );
  }, [category]);

  const questionMap = useMemo(
    () => subQuestions && questionArrayToMap(subQuestions),
    [subQuestions],
  );

  const answersByQuestionId: { [key: string]: CareProfileAnswer } =
    useMemo(() => {
      return (
        careProfileAnswers?.reduce((acc, answer: CareProfileAnswer) => {
          let tableQuestionAnswers: { [key: string]: { value: string } } = {};
          if (
            answer?.careProfileQuestion?.fieldType === FieldType.QuestionTable
          ) {
            const tableQuestionValues = JSON.parse(answer.value);
            tableQuestionValues.forEach((tableQuestionValue: any) => {
              Object.keys(tableQuestionValue).forEach((key) => {
                const parentId = answer?.careProfileQuestion?.id;
                if (parentId) {
                  tableQuestionAnswers[parentId + '-' + key] = {
                    value: tableQuestionValue[key],
                  };
                }
              });
            });
          }
          return {
            ...acc,
            [answer.careProfileQuestionId]: answer,
            ...tableQuestionAnswers,
          };
        }, {}) || {}
      );
    }, [careProfileAnswers]);

  const isQuestionAnswered = useCallback(
    (question: CareProfileQuestion): boolean => {
      let questionId = question.id;
      const isTableQuestion =
        questionMap?.[question.parentId]?.fieldType === FieldType.QuestionTable;
      if (isTableQuestion) {
        questionId = question.parentId + '-' + question.id;
      }
      const answer = answersByQuestionId[questionId]?.value;

      return answer !== undefined && answer !== '';
    },
    [answersByQuestionId, questionMap],
  );

  const createManyAnswerWithProfileID = useCallback(
    (answers: CareProfileAnswer[]) =>
      createManyAnswer({ careProfileId: careProfileID, answers }),
    [createManyAnswer, careProfileID],
  );

  const isQuestionRelevant = useCallback(
    (q: CareProfileQuestion): boolean => {
      if (!questionMap || !answersByQuestionId) return true;

      const relevanceChecker = (question: CareProfileQuestion): boolean => {
        if (!question.parentId || question.parentId === '') {
          return true;
        }

        const parentQuestion = questionMap[question.parentId];

        if (!relevanceChecker(parentQuestion)) {
          return false;
        }

        if (!isQuestionAnswered(parentQuestion)) {
          return true;
        }

        const parentAnswer = answersByQuestionId[question.parentId]?.value;

        return isSubQuestionRelevant(question, parentAnswer);
      };

      return relevanceChecker(q);
    },
    [questionMap, answersByQuestionId, isQuestionAnswered],
  );

  const careCircleMember = useMemo(() => {
    return careCircle?.careCircleMembers?.find(
      (member) => member.account.id === careCircle?.primaryCaregiverAccount.id,
    );
  }, [careCircle]);

  const formsByQuestionId = useMemo(() => {
    let m: { [k: string]: CareProfileForm[] } = {};

    category?.careProfileSubCategories.forEach((subCategory) => {
      subCategory.careProfileForms.forEach((form) => {
        form.careProfileQuestions?.forEach((question) => {
          if (!m[question.id]) {
            m[question.id] = [];
          }

          m[question.id].push(form);
        });
      });
    });

    return m;
  }, [category]);

  const formsBySubquestionId = useMemo(() => {
    if (!questionMap) return {};

    const formsMap: { [k: string]: string[] } = {};

    Object.keys(questionMap).forEach((questionID) => {
      let q = questionMap[questionID];

      if (!q) return;

      const newMap: { [k: string]: true } = {};

      while (q) {
        (formsByQuestionId?.[q.id] || []).forEach((f) => {
          newMap[f.id] = true;
        });
        q = questionMap[q.parentId];
      }

      formsMap[questionID] = Object.keys(newMap);
    });

    return formsMap;
  }, [questionMap, formsByQuestionId]);

  const forms = useMemo(() => {
    if (!category) return [];

    return category.careProfileSubCategories
      .map((subCategory) => {
        return subCategory.careProfileForms.map((form: any) => {
          let order = 0;
          let fields: any = {};

          const assignField = (question: CareProfileQuestion) => {
            let table = {};
            if (question.fieldTable) {
              const splitTable = question.fieldTable?.split(';');
              const title = splitTable[0];
              const columns = splitTable[1].split(',');
              const rows = splitTable[2].split(',');
              table = {
                title: title,
                columns: columns,
                rows: rows,
              };
            }

            order += 10;

            const mapField = (question: CareProfileQuestion) => {
              const labels: { [key: string]: string } = {};
              if (question.fieldLabels) {
                question.fieldLabels.split(';').forEach((label) => {
                  const labelSplit = label.split(':');
                  labels[labelSplit[0]] = labelSplit[1];
                });
              }
              return {
                id: question.id,
                label: question.label
                  .replace(
                    '{careRecipient}',
                    careCircle?.careRecipientAccount?.firstName ||
                      'your loved one',
                  )
                  .replace(
                    '{caregiver}',
                    careCircle?.primaryCaregiverAccount?.firstName ||
                      'caregiver',
                  ),
                isRequired: question.isRequired,
                placeholder: question.placeholder,
                tooltip: question.tooltip,
                validation: validationStringToMethod(
                  question.validation as string,
                ),
                field: {
                  type: question.fieldType,
                  limits: {
                    min: parseInt(question.fieldLimits?.split(',')?.[0]),
                    max: parseInt(question.fieldLimits?.split(',')?.[1]),
                  },
                  labels: labels,
                  options: question.fieldOptions
                    ?.map((option: any) => ({
                      value: option.id,
                      label: option.label,
                      order: option.order,
                    }))
                    .sort((a: any, b: any) => a.label.localeCompare(b.label)),
                  table: table,
                },
              };
            };

            const mappedField = mapField(question);

            fields[question.id] = {
              ...mappedField,
              order,
              field: {
                ...mappedField.field,
                subfields:
                  question.fieldType === FieldType.QuestionTable
                    ? question.careProfileSubQuestions?.map((subq: any) =>
                        mapField(subq),
                      )
                    : undefined,
              },
            };
          };
          const questions = [...(form.careProfileQuestions || [])];
          questions.sort((a, b) => a.order - b.order);
          questions.forEach((question: any) => {
            assignField(question);

            const answer = answers?.[form.id]?.[question.id];
            const createSubQuestions = (question: any, answer: any) => {
              if (question.fieldType === FieldType.QuestionTable) return;

              if (answer !== undefined) {
                if (answer === true) answer = 'true';
                if (answer === false) answer = 'false';

                const subQuestions = [
                  ...(question.careProfileSubQuestions || []),
                ];
                subQuestions.sort((a: any, b: any) => a.order - b.order);
                subQuestions.forEach((subQuestion: any) => {
                  let conditionValue = subQuestion.conditionValue;

                  switch (subQuestion.conditionOperator) {
                    case 'equal':
                      if (answer === conditionValue) break;
                      return;
                    case 'not-equal':
                      if (answer !== conditionValue) break;
                      return;
                    case 'contains':
                      if (answer.includes(conditionValue)) break;
                      return;
                    case 'in':
                      if (!conditionValue) break;
                      if (conditionValue.includes(answer)) break;
                      return;
                  }

                  assignField(subQuestion);

                  const subQuestionAnswer =
                    answers?.[form.id]?.[subQuestion.id];
                  if (subQuestionAnswer !== undefined) {
                    createSubQuestions(subQuestion, subQuestionAnswer);
                  }
                });
              }
            };

            createSubQuestions(question, answer);
          });

          return {
            id: form.id,
            title: form.title,
            description: form.description,
            isComplete: form.careProfileQuestions?.every(
              (question: any) =>
                (answers?.[form.id]?.[question.id] !== undefined ||
                  question.isRequired === false) &&
                answers?.[form.id]?.[question.id] !== '',
            ),
            fields: fields,
          };
        });
      })
      .flat();
  }, [category, careCircle, answers]);

  const numCompleted = useMemo(() => {
    if (!subQuestions) return 0;

    return subQuestions.filter((q: CareProfileQuestion) => {
      return (isQuestionAnswered(q) || !isQuestionRelevant(q)) && q.isRequired;
    }).length;
  }, [subQuestions, isQuestionAnswered, isQuestionRelevant]);

  const sections: Section[] = useMemo(() => {
    if (!careProfileSubCategories || careProfileSubCategories.length === 0) {
      return [];
    }

    const isFormInProgress = (form: Form): boolean => {
      return form?.careProfileQuestions?.some(
        (question: Question) => answersByQuestionId[question.id],
      );
    };

    const isFormComplete = (form: Form): boolean => {
      return form?.careProfileQuestions?.every(
        (question: Question) => answersByQuestionId[question.id],
      );
    };

    return careProfileSubCategories.map((element: Element) => {
      const subSections = element.careProfileForms.map((form: Form) => ({
        title: form.title
          .replace(
            '{careRecipient}',
            careCircle?.careRecipientAccount?.firstName || 'your loved one',
          )
          .replace(
            '{caregiver}',
            careCircle?.primaryCaregiverAccount?.firstName || 'caregiver',
          ),
        id: form.id,
        isComplete: isFormInProgress(form),
        to: `/${element.careProfileCategoryId}/${form.id}`,
      }));

      return {
        title: element.title
          .replace(
            '{careRecipient}',
            careCircle?.careRecipientAccount?.firstName || 'your loved one',
          )
          .replace(
            '{caregiver}',
            careCircle?.primaryCaregiverAccount?.firstName || 'caregiver',
          ),
        id: element.id,
        subSections,
        sectionStatus: element.careProfileForms.every(isFormComplete)
          ? 'complete'
          : element.careProfileForms.some(isFormInProgress)
            ? 'inProgress'
            : 'notStarted',
      };
    });
  }, [careProfileSubCategories, answersByQuestionId, careCircle]);

  const numTotal = useMemo(() => {
    if (!subQuestions) return 0;
    return subQuestions.filter((q: CareProfileQuestion) => {
      return q.isRequired;
    }).length;
  }, [subQuestions]);

  // effects
  useEffect(() => {
    if (!careProfileAnswers || !formsBySubquestionId) return;

    const careAnswers: any = {};

    careProfileAnswers.forEach((answer) => {
      formsBySubquestionId[answer.careProfileQuestionId]?.forEach((formID) => {
        if (!careAnswers[formID]) {
          careAnswers[formID] = {};
        }

        careAnswers[formID][answer.careProfileQuestionId] = answer.value;
      });
    });

    setAnswers?.(careAnswers);
  }, [careProfileAnswers, formsBySubquestionId, setAnswers]);

  return {
    answers,
    setAnswers,
    careProfileID,
    careProfileCategories,
    category,
    forms,
    numCompleted,
    numTotal,
    careCircle,
    careProfileFormId,
    careCircleMember,
    sections,
    createManyAnswer: createManyAnswerWithProfileID,
  };
};
