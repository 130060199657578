import api from '.';
import { CareHomeShortlist } from '@karehero/models';

export const careHomeShortlistApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createShortlist: builder.mutation<CareHomeShortlist, CareHomeShortlist>({
      query: (careHomeShortlist) => ({
        url: '/care-home-shortlist',
        method: 'POST',
        body: careHomeShortlist,
      }),
      invalidatesTags: ['CareHomeShortlist'],
    }),
    updateShortlist: builder.mutation<
      CareHomeShortlist,
      Partial<CareHomeShortlist> & { id: string }
    >({
      query: ({ id, ...careHomeShortlist }) => ({
        url: `/care-home-shortlist/${id}`,
        method: 'PUT',
        body: careHomeShortlist,
      }),
      invalidatesTags: ['CareHomeShortlist'],
    }),
    getShortlist: builder.query<CareHomeShortlist, string>({
      query: (id) => ({ url: `/care-home-shortlist/${id}` }),
      providesTags: ['CareHomeShortlist'],
    }),
    getShortlistBySession: builder.query<any, void>({
      query: () => ({ url: `/care-profile/care-home-shortlist` }),
      providesTags: ['CareHomeShortlist'],
    }),
    getShortlistDataBySession: builder.query<any, void>({
      query: () => ({ url: `/care-profile/care-home-shortlist/data` }),
      providesTags: ['CareHomeShortlistData'],
    }),
    getShortlistByCareProfileId: builder.query<CareHomeShortlist, string>({
      query: (careProfileID) => ({
        url: `/care-profile/${careProfileID}/care-home-shortlist`,
      }),
      providesTags: ['AdminCareHomeShortlist'],
    }),
    getShortlistDataByCareProfileId: builder.query<CareHomeShortlist, string>({
      query: (careProfileID) => ({
        url: `/care-profile/${careProfileID}/care-home-shortlist/data`,
      }),
      providesTags: ['AdminCareHomeShortlistData'],
    }),
    markShortlistCareHomeSecuredByCareProfileId: builder.mutation<
      any,
      CareHomeShortlist
    >({
      query: (data: CareHomeShortlist) => ({
        url: `/care-profile/${data.careProfileID}/care-home-secured`,
        method: 'POST',
        body: { providerID: data.securedRegulatoryID },
      }),
      invalidatesTags: ['AdminCareHomeShortlist'],
    }),
    markShortlistCareHomeSecured: builder.mutation<any, string>({
      query: (regulatoryID: string) => ({
        url: `/care-profile/care-home-secured`,
        method: 'POST',
        body: { regulatoryID },
      }),
      invalidatesTags: ['CareHomeShortlist'],
    }),
  }),
});

export const {
  useCreateShortlistMutation,
  useUpdateShortlistMutation,
  useGetShortlistQuery,
  useGetShortlistBySessionQuery,
  useGetShortlistDataBySessionQuery,
  useGetShortlistByCareProfileIdQuery,
  useGetShortlistDataByCareProfileIdQuery,
  useMarkShortlistCareHomeSecuredMutation,
  useMarkShortlistCareHomeSecuredByCareProfileIdMutation,
} = careHomeShortlistApi;

export default careHomeShortlistApi;
