// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { Button, CopyText, EditorPage, Label } from '@karehero/llama';

// editor
import { createFieldHelper, FieldType } from '@karehero/llama';

// store
import { Organization, OrganizationType } from '@karehero/models';
import {
  useCreateOrganizationMutation,
  useGetAllOrganizationTypeQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'store/api/organization';
import { newToast } from 'store/slices/toastSlice';
import { useDispatch } from 'react-redux';

// qr code
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';

/**
 * OrganizationEditor is the dashboard page.
 */
const OrganizationEditor = () => {
  // ref
  const qrCodeCanvasRef = useRef<HTMLCanvasElement>(null);

  // state
  const [organization, setOrganization] = useState<Partial<Organization>>({});

  // hooks
  const dispatch = useDispatch();
  const { organizationID } = useParams();
  const { data: organizationTypes } = useGetAllOrganizationTypeQuery();

  // memo
  const inviteKeyRegistrationURL = useMemo(
    () => `${window.location.origin}/register/organization/${organization.id}`,
    [organization],
  );

  const isEditing = useMemo<boolean>(() => !!organizationID, [organizationID]);

  const fields = useMemo(() => {
    return createFieldHelper<Organization>({
      name: {
        label: 'Name',
        field: {
          type: FieldType.Text,
        },
      },
      organizationTypeId: {
        label: 'Type',
        field: {
          type: FieldType.Select,
          options: organizationTypes?.map((type: OrganizationType) => ({
            value: type.id,
            label: type.name,
          })),
        },
      },
      organizationDomains: {
        label: 'Email domains',
        field: {
          type: FieldType.MultiSelectBox,
          isCustom: true,
          options: [],
        },
      },
      isInviteKeyEnabled: {
        label: 'Invite Key Enabled',
        field: {
          type: FieldType.Switch,
        },
      },
      isInviteCode: {
        label: 'Invite Code',
        field: {
          type: FieldType.Switch,
        },
      },
      ...(organization.isInviteKeyEnabled
        ? {
            isEmailRegistrationEnabled: {
              label: 'Email Registration Enabled',
              field: {
                type: FieldType.Switch,
              },
            },
            inviteKeyName: {
              label: 'Invite Key Name',
              field: {
                type: FieldType.Text,
              },
            },
          }
        : {}),
    });
  }, [organization, organizationTypes]);

  const computedOrganization = useMemo(() => {
    return {
      ...organization,
      inviteKeyName: organization.isInviteKeyEnabled
        ? organization.inviteKeyName
        : undefined,
      organizationDomains: JSON.stringify(
        organization?.organizationDomains?.map((domain) => domain?.domain),
      ),
    };
  }, [organization]);

  // hooks
  const navigate = useNavigate();
  const [createOrganization] = useCreateOrganizationMutation();
  const [updateOrganization] = useUpdateOrganizationMutation();

  const { data: existingOrganization, isLoading } = useGetOrganizationQuery(
    organizationID || '',
  );

  // effects
  useEffect(() => {
    if (isLoading || !existingOrganization) return;

    setOrganization(existingOrganization);
  }, [isLoading, existingOrganization]);

  // methods
  const handleSetOrganization = useCallback(
    (org: Partial<Organization> & { organizationDomains: string }) => {
      setOrganization((prev) => ({
        ...prev,
        ...org,
        inviteKeyName: org.isInviteKeyEnabled ? org.inviteKeyName : undefined,
        organizationDomains: JSON.parse(org.organizationDomains || '[]').map(
          (domain: string) => ({ domain: domain }),
        ),
      }));
    },
    [setOrganization],
  );

  const handleSave = useCallback(async () => {
    organization.organizationType = organizationTypes?.find(
      (type) => type.id === organization.organizationTypeId,
    );
    isEditing
      ? updateOrganization(organization as Organization)
      : createOrganization(organization as Organization);

    navigate('/admin/organization/manage');
    return;
  }, [
    navigate,
    createOrganization,
    updateOrganization,
    organization,
    isEditing,
    organizationTypes,
  ]);

  const handleOnCopy = useCallback(() => {
    dispatch(
      newToast({
        description: 'Invite key registration URL copied to clipboard',
        variant: 'success',
      }),
    );
  }, [dispatch]);

  const handleCopyQRCode = useCallback(() => {
    if (!qrCodeCanvasRef.current) return;

    qrCodeCanvasRef.current.toBlob((blob) => {
      if (!blob) return;

      navigator.clipboard.write([
        new ClipboardItem({
          'image/png': blob,
        }),
      ]);
    });

    dispatch(
      newToast({
        description: 'Invite key registration QR code copied to clipboard',
        variant: 'success',
      }),
    );
  }, [dispatch]);

  // memo
  const isSaveDisabled = useMemo(() => {
    // Check whether Invite Key config is valid
    if (
      organization.isInviteKeyEnabled &&
      (organization.inviteKeyName || '').length === 0
    ) {
      return true;
    }

    // Check whether org domains have changed
    const orgDomains = JSON.stringify(
      organization.organizationDomains?.map((domain) => domain.domain) || '[]',
    );
    const existingDomains = JSON.stringify(
      (existingOrganization?.organizationDomains || []).map(
        (domain) => domain.domain,
      ),
    );
    if (orgDomains !== existingDomains) {
      return false;
    }

    // Check whether the rest of org has changed
    return (
      JSON.stringify({ ...organization, organizationDomains: [] }) ===
      JSON.stringify({ ...existingOrganization, organizationDomains: [] })
    );
  }, [organization, existingOrganization]);

  return (
    <EditorPage
      title={isEditing ? 'Modify Organization' : 'New Organization'}
      fields={fields}
      value={computedOrganization}
      onChange={handleSetOrganization}
      saveLabel={isEditing ? 'Save' : 'Create'}
      cancelLabel='Close'
      onSave={handleSave}
      onCancel={() => navigate('/admin/organization/manage')}
      isSaveDisabled={isSaveDisabled}
      start={
        organization.isInviteKeyEnabled && (
          <InviteKeyDetails>
            <Label
              id='invite-key-registration-url'
              text='Invite Key Registration URL'
            />
            <ValueWrapper>
              <StyledCopyText
                value={inviteKeyRegistrationURL}
                onCopy={handleOnCopy}
              />
              <QRCodeWrapper>
                <QRCodeSVG
                  value={inviteKeyRegistrationURL}
                  size={196}
                  marginSize={4}
                />
                <Button
                  ariaLabel='copy qr code'
                  onPress={handleCopyQRCode}
                  variant='secondary'
                  iconLeft='copy'
                >
                  Copy QR Code
                </Button>
                <StyledQRCodeCanvas
                  ref={qrCodeCanvasRef}
                  value={inviteKeyRegistrationURL}
                  size={1024}
                  marginSize={4}
                />
              </QRCodeWrapper>
            </ValueWrapper>
          </InviteKeyDetails>
        )
      }
    />
  );
};

const InviteKeyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[4]};
  width: 100%;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
  align-items: flex-start;
`;

const StyledCopyText = styled(CopyText)`
  flex: 1;
  width: 100%;
`;

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  width: 100%;
  background: ${(props) => props.theme.color.neutral[10]};
`;

const StyledQRCodeCanvas = styled(QRCodeCanvas)`
  display: none;
`;

export default OrganizationEditor;
