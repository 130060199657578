// react
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

// components
import { InvitePortal as InvitePortalPage } from '@karehero/llama';
import { useParams } from 'react-router-dom';

// store
import { useSelector } from 'store';
import {
  useGetAllOrganizationAccountsAdminQuery,
  useGetOrganizationQuery,
  useInviteMembersToOrganizationAdminMutation,
  useAddInviteKeysToOrganizationAdminMutation,
  useReinviteMemberMutation,
  useResendEmailInvitesToAllMembersMutation,
} from 'store/api/organization';
import { newToast } from 'store/slices/toastSlice';

/**
 * OrganizationMemberTable shows the accounts and invited members of an organization
 */
const OrganizationMemberTable = () => {
  // params
  const { organizationID } = useParams();

  // hooks
  const [reinviteMember] = useReinviteMemberMutation();
  const [resendEmailInvitesToAllMembers] =
    useResendEmailInvitesToAllMembersMutation();
  const [inviteMembers] = useInviteMembersToOrganizationAdminMutation();
  const [addInviteKeys] = useAddInviteKeysToOrganizationAdminMutation();
  const dispatch = useDispatch();
  const { data: organization } = useGetOrganizationQuery(organizationID || '');
  const { data: accounts } = useGetAllOrganizationAccountsAdminQuery(
    organizationID || '',
  );
  const account = useSelector((state) => state.iam.account);

  const isAdmin = account?.accountRole.name === 'KareHero Administrator';

  // methods
  const handleResendInvite = useCallback(
    async (id?: string) => {
      if (!id) return;
      const res = await reinviteMember(id);

      if ('error' in res) {
        console.error(res.error);
        return;
      }
    },
    [reinviteMember],
  );

  const handleResendAllEmail = useCallback(async () => {
    const res = await resendEmailInvitesToAllMembers(organization?.id || '');

    if ('error' in res) {
      console.error('Error resending email invites:', res.error);
      dispatch(
        newToast({
          title: 'Error',
          description: 'Failed to resend email invites.',
        }),
      );
      return;
    }

    dispatch(
      newToast({
        title: 'Success',
        description: 'Email invites resent successfully.',
      }),
    );
  }, [resendEmailInvitesToAllMembers, organization?.id, dispatch]);

  return (
    <InvitePortalPage
      accounts={accounts}
      resendInvite={handleResendInvite}
      resendEmailInvites={handleResendAllEmail}
      organization={organization}
      inviteMembers={(req) =>
        organizationID && inviteMembers({ ...req, organizationID })
      }
      addInviteKeys={(req) =>
        organizationID && addInviteKeys({ ...req, organizationID })
      }
      notifyToast={(req: { title: string; description: string }) =>
        dispatch(newToast(req))
      }
      isAdmin={isAdmin}
    />
  );
};

export default OrganizationMemberTable;
