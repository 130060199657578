import { jsx as _jsx } from "react/jsx-runtime";
// components
import { HubSpotChat } from "../../organisms";
/**
 * Messenger shows all chats.
 */
export const Messenger = ({ kindeEmail, hubspotIdentificationToken, }) => {
    return (_jsx(HubSpotChat, { kindeEmail: kindeEmail, token: hubspotIdentificationToken }));
};
Messenger.displayName = 'Messenger';
export default Messenger;
