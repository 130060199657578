// pdfmake
import { background, createPdf, pageSize } from 'pdf';

// assets
import { carePlanLogo } from 'pdf';

// store
import { CareProfileCategory } from '@karehero/models';

export const header = (title: string) => {
  return [
    {
      text: title,
      style: 'header',
      fontSize: 14,
      color: '#003413',
      bold: true,
      margin: [0, 40, 0, 0],
    },
    '',
  ];
};

export const row = (title: string, value: string) => {
  return [{ text: title, color: '#003413' }, { text: value }];
};

export const table = (body: any) => {
  return {
    layout: 'lightHorizontalLines',
    table: {
      headerRows: 1,
      widths: ['50%', '50%'],
      body: body,
    },
  };
};

export const flattenQuestion = (questions: any) => {
  let tmpQuestions: any[] = [];
  questions.forEach((question: any) => {
    tmpQuestions.push(question);
    if (question.careProfileSubQuestions.length > 0) {
      tmpQuestions = tmpQuestions.concat(
        flattenQuestion(question.careProfileSubQuestions),
      );
    }
  });
  return tmpQuestions;
};

const getAnswer = (
  careProfileCategories: CareProfileCategory[],
  answers: any,
  categoryId: string,
  subCategoryId: string,
  formId: string,
  questionId: string,
  sanitise?: string,
  careRecipientName?: string,
) => {
  let answer = answers?.[formId]?.[questionId];
  if (!answer) return;

  const questions = careProfileCategories
    ?.find((category) => category.id === categoryId)
    ?.careProfileSubCategories.find(
      (subCategory) => subCategory.id === subCategoryId,
    )
    ?.careProfileForms.find((form) => form.id === formId)?.careProfileQuestions;

  switch (sanitise) {
    case 'date':
      answer = new Date(answer).toLocaleDateString();
      break;
    case 'address':
      const addressObject = JSON.parse(answer);
      let addressString = '';
      if (addressObject?.addressLine1) {
        addressString += `${addressObject?.addressLine1}\n`;
      }
      if (addressObject?.addressLine2) {
        addressString += `${addressObject?.addressLine2}\n`;
      }
      if (addressObject?.addressLine3) {
        addressString += `${addressObject?.addressLine3}\n`;
      }
      if (addressObject?.city) addressString += `${addressObject?.city}\n`;
      if (addressObject?.postalCode) {
        addressString += `${addressObject?.postalCode}\n`;
      }

      // check if last two characters are new line
      if (addressString.slice(-2) === '\n') {
        addressString = addressString.slice(0, -2);
      }

      answer = addressString;
      break;
    case 'multi-select':
    case 'multi-select-chip':
    case 'multi-select-box':
      const selectArray = JSON.parse(answer);

      const options = flattenQuestion(questions).find(
        (question) => question.id === questionId,
      )?.fieldOptions;

      const labeledArray = selectArray.map((option: any) => {
        const optionLabel = options?.find(
          (optionItem: any) => optionItem.id === option,
        )?.label;
        if (!optionLabel) return option;
        return optionLabel;
      });

      let labeledArrayString = '';
      labeledArray.forEach((element: string) => {
        labeledArrayString += `${element}\n`;
      });

      // check if last two characters are new line
      if (labeledArrayString.slice(-2) === '\n') {
        labeledArrayString = labeledArrayString.slice(0, -2);
      }

      answer = labeledArrayString;
      break;
    case 'boolean':
      answer = answer === 'true' ? 'Yes' : 'No';
      break;
    case 'slider-select':
    case 'select':
      const selectQuestions = careProfileCategories
        ?.find((category) => category.id === categoryId)
        ?.careProfileSubCategories.find(
          (subCategory) => subCategory.id === subCategoryId,
        )
        ?.careProfileForms.find(
          (form) => form.id === formId,
        )?.careProfileQuestions;

      answer = flattenQuestion(selectQuestions)
        .find((question) => question.id === questionId)
        ?.fieldOptions.find((option: any) => option.id === answer)?.label;
      break;
    case 'radio':
      const radioQuestions = careProfileCategories
        ?.find((category) => category.id === categoryId)
        ?.careProfileSubCategories.find(
          (subCategory) => subCategory.id === subCategoryId,
        )
        ?.careProfileForms.find(
          (form) => form.id === formId,
        )?.careProfileQuestions;

      answer = flattenQuestion(radioQuestions)
        .find((question) => question.id === questionId)
        ?.fieldOptions.find((option: any) => option.id === answer)?.label;
      break;
    case 'slider-severity':
      let tmpAnswer = '';
      switch (answer) {
        case '1':
          tmpAnswer = '1 (None)';
          break;
        case '2':
          tmpAnswer = '2 (Mild)';
          break;
        case '3':
          tmpAnswer = '3 (Moderate)';
          break;
        case '4':
          tmpAnswer = '4 (Severe)';
          break;
        case '5':
          tmpAnswer = '5 (Critical)';
          break;
      }
      answer = tmpAnswer;
      break;
    case 'boolean-table':
      const booleanTableObject = JSON.parse(answer);
      let tableString = '';
      Object.keys(booleanTableObject).forEach((row) => {
        const columns = Object.keys(booleanTableObject[row]);
        let rowString = `${row}: `;
        columns.forEach((column) => {
          rowString += `${column}, `;
        });
        rowString = rowString.slice(0, -2);
        tableString += `${rowString}\n`;
      });
      answer = tableString;
      break;
    case 'question-table':
      const subQuestions = flattenQuestion(questions).find(
        (question) => question.id === questionId,
      ).careProfileSubQuestions;

      const questionTableObjectArray = JSON.parse(answer);
      let questionTableString = '';

      questionTableObjectArray.forEach((questionTableObject: any) => {
        Object.keys(questionTableObject).forEach((key) => {
          const value = questionTableObject[key];
          const keyLabel = subQuestions.find(
            (subQuestion: any) => subQuestion.id === key,
          )?.label;
          const valueLabel = subQuestions
            .find((subQuestion: any) => subQuestion.id === key)
            ?.fieldOptions.find((option: any) => option.id === value)?.label;
          questionTableString += `${keyLabel}:\n${valueLabel || value}\n\n`;
        });
        questionTableString += '-\n\n';
      });
      questionTableString = questionTableString.slice(0, -3);
      questionTableString = questionTableString.replaceAll(
        '{careRecipient}',
        careRecipientName || 'the care recipient',
      );
      answer = questionTableString;
      break;
  }

  return answer;
};

export const generateTableDefs = (
  careProfileCategories: CareProfileCategory[],
  answers: any,
  firstName: string = 'the care recipient',
  categoryID: string = 'care-assessment',
) => {
  let tableDefs: any[] = [];
  careProfileCategories.forEach((category) => {
    if (category.id !== categoryID) return;
    category.careProfileSubCategories.forEach((subCategory) => {
      subCategory.careProfileForms.forEach((form) => {
        let tableDef: any[] = [
          header(form.title.replace('{careRecipient}', firstName)),
        ];
        const addQuestions = (questions: any) => {
          questions.forEach((question: any) => {
            const answer = getAnswer(
              careProfileCategories,
              answers,
              category.id,
              subCategory.id,
              form.id,
              question.id,
              question.fieldType,
              firstName,
            );
            if (!answer) return;
            tableDef.push(
              row(question.label.replace('{careRecipient}', firstName), answer),
            );
            if (
              question.careProfileSubQuestions &&
              question.careProfileSubQuestions.length > 0
            ) {
              addQuestions(question.careProfileSubQuestions);
            }
          });
        };
        form?.careProfileQuestions && addQuestions(form.careProfileQuestions);
        tableDefs.push(table(tableDef));
      });
    });
  });
  return tableDefs;
};

export const careProfileDocDefinition = (
  careProfileCategories: CareProfileCategory[],
  answers: any,
  careRecipientName: string = 'the care recipient',
  categoryID: string = 'care-assessment',
  title: string = 'Care Assessment',
) => {
  const firstName =
    careRecipientName !== 'the care recipient'
      ? careRecipientName.split(' ')[0]
      : 'the care recipient';

  const tableDefs = generateTableDefs(
    careProfileCategories,
    answers,
    firstName,
    categoryID,
  );

  return createPdf({
    content: [
      {
        image: 'logo',
        width: 200,
        alignment: 'center',
      },
      {
        text: title,
        alignment: 'center',
        color: '#003413',
        fontSize: 20,
        margin: [0, 10, 0, 0],
      },
      table([header(`About ${firstName}`), row('Name', careRecipientName)]),
      ...tableDefs,
    ],
    images: {
      logo: carePlanLogo,
    },
    pageSize: pageSize,
    background: background('#ffffff'),
  });
};
