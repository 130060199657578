// react
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useCareProfile } from 'hooks';

// pdf
import { careProfileDocDefinition } from 'pdf';

// components
import { CareProfileRoot, SuccessContent } from '@karehero/llama';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * CareProfileComplete is the dashboard page.
 */
const CareProfileComplete = () => {
  // hooks
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const {
    numCompleted,
    numTotal,
    careCircle,
    answers,
    careProfileCategories,
    sections,
    forms,
  } = useCareProfile({ categoryID: 'care-assessment' });

  // memos
  const careRecipientName = useMemo(
    () =>
      `${careCircle?.careRecipientAccount?.firstName || ''} ${
        careCircle?.careRecipientAccount?.lastName || ''
      }`,
    [careCircle],
  );

  const toPrevious = useMemo(() => {
    if (!forms || forms.length === 0) return '/care-profile';
    return `/care-assessment/${forms[forms.length - 1].id}`;
  }, [forms]);

  // methods
  const handlePrintPdf = useCallback(() => {
    if (!careProfileCategories) return;
    careProfileDocDefinition(
      careProfileCategories,
      answers,
      careRecipientName,
      'care-assessment',
      'Care Assessment',
    ).open();
  }, [careProfileCategories, answers, careRecipientName]);

  const handleNext = useCallback(() => {
    track(MixpanelEvent.CareAssessmentSubmitted, {});
    navigate('/book-call?category=find-care');
  }, [track, navigate]);

  return (
    <CareProfileRoot
      nextLabel='Submit Form'
      onPrintPdf={handlePrintPdf}
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      toExit='/care-profile?tab=2'
      onNext={handleNext}
      toPrevious={toPrevious}
      isFormCompleted={numTotal === numCompleted}
    >
      <SuccessContent
        header='Care Assessment Ready For Review'
        cardHeader='Your next steps'
        fixtures={[
          {
            icon: 'check-circle',
            text: 'Review your Care Assessment answers to ensure all the needs captured are correct',
            iconColor: 'success' as 'success',
          },
          {
            icon: 'user',
            text: 'Once submitted you can chat to your Care Expert regarding your Care Assessment',
            iconColor: 'blue' as 'blue',
          },
          {
            icon: 'file-contract',
            text: 'Your Care Expert will now provide a care search shortlist tailored by your Care Assessment',
            iconColor: 'primary' as 'primary',
          },
        ]}
      />
    </CareProfileRoot>
  );
};

export default CareProfileComplete;
