// components
import { RecipientProfileCarePlan } from '@karehero/llama';

// hooks
import { useCareCircle, useCareProfile } from 'hooks';

/**
 * CarePlan shows the CarePlanPage component.
 */
const CarePlan = () => {
  // hooks
  const { currentCareCircle } = useCareCircle();
  const { numTotal, numCompleted } = useCareProfile();

  return (
    <RecipientProfileCarePlan
      firstName={currentCareCircle?.careRecipientAccount?.firstName}
      limit={numTotal}
      progress={numCompleted}
      toCareProfile={`/care-assessment/care-assessment-profile`}
      toBookCall='/book-call'
    />
  );
};

export default CarePlan;
