var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { css } from 'styled-components';
// components
import { BaseField, Button } from "../../atoms";
import { EditorFields } from "../EditorFields/EditorFields";
/**
 * QuestionTable is the question table component.
 */
export const QuestionTable = (_a) => {
    var _b;
    var { value, fieldValue, fieldKey, onChange, isDisabled = false } = _a, props = __rest(_a, ["value", "fieldValue", "fieldKey", "onChange", "isDisabled"]);
    const newSubFields = {};
    (_b = fieldValue.field.subfields) === null || _b === void 0 ? void 0 : _b.forEach((subField) => {
        var _a;
        newSubFields[((_a = subField.id) === null || _a === void 0 ? void 0 : _a.toString()) || ''] = subField;
    });
    let myValue = (value === null || value === void 0 ? void 0 : value[fieldKey]) ? JSON.parse(value === null || value === void 0 ? void 0 : value[fieldKey]) : [{}];
    if (myValue.length === 0) {
        myValue = [{}];
    }
    return (_jsxs(_Fragment, { children: [_jsx(BaseField, Object.assign({}, props, { children: _jsxs(StyledTable, { children: [myValue.map((v, i) => {
                            return (_jsxs(StyledTableRow, { children: [_jsx(StyledInnerEditorFields, { children: _jsx(EditorFields, { fields: newSubFields, value: v, onChange: (w) => {
                                                const newValue = [...myValue];
                                                newValue[i] = w;
                                                onChange(JSON.stringify(newValue));
                                            }, isDisabled: isDisabled }) }), myValue.length >= 2 && (_jsx(Button, Object.assign({ ariaLabel: 'remove more entries', variant: 'secondary', isDisabled: isDisabled, onPress: () => onChange(JSON.stringify([
                                            ...myValue.slice(0, i),
                                            ...myValue.slice(i + 1),
                                        ])) }, { children: "Remove" })))] }, i));
                        }), myValue.length === 0 ? (_jsx(StyledTableRow, { children: _jsx(NoTableDataMessage, { children: fieldValue.placeholder }) })) : null] }) })), _jsx(Button, Object.assign({ ariaLabel: 'add more entries', isFullWidth: true, variant: 'secondary', onPress: () => onChange(JSON.stringify([...myValue, {}])), iconLeft: 'plus', isDisabled: isDisabled }, { children: "Add another" }))] }));
};
const StyledInnerEditorFields = styled.div `
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
  padding: ${(props) => props.theme.spacing[12]} 0;
`;
const StyledTable = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const StyledTableRow = styled.div `
  ${(props) => css `
    border: 1px solid ${props.theme.color.neutral[60]};
    border-radius: 16px;
  `}
  width: 100%;
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};
  gap: ${(props) => props.theme.spacing[16]};
`;
const NoTableDataMessage = styled.div `
  width: 100%;
  text-align: center;
`;
QuestionTable.displayName = 'QuestionTable';
export default QuestionTable;
