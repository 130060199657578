import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { createPortal } from 'react-dom';
// components
import { BaseField, Button, Checkbox, Icon, Input, } from "..";
import { CardContainer } from "../../templates";
import Chip from "./Chip";
/**
 * MultiSelect allows the user to select a value from a list of options.
 */
export const MultiSelect = ({ id, label, description, tooltip, value, onChange, options, placeholder = 'MultiSelect', ariaLabel, isDisabled = false, isRequired = false, isHideTitle = false, isCustom = false, }) => {
    // refs
    const valueRef = useRef(null);
    const selectedRef = useRef(null);
    const contentRef = useRef(null);
    // state
    const [elPortal, setElPortal] = useState(document.getElementById('portal'));
    const [isOpen, setIsOpen] = useState(false);
    const [numAdditional, setNumAdditional] = useState(0);
    const [customValue, setCustomValue] = useState('');
    const [isAddAnother, setIsAddAnother] = useState(false);
    const [triggerBoundingBox, setTriggerBoundingBox] = useState({});
    // callback refs
    const triggerRef = useCallback((node) => {
        if (node) {
            setTriggerBoundingBox(node.getBoundingClientRect());
        }
    }, [isOpen]);
    // memo
    const triggerTop = useMemo(() => (triggerBoundingBox && (triggerBoundingBox === null || triggerBoundingBox === void 0 ? void 0 : triggerBoundingBox.bottom)) || 0, [triggerBoundingBox]);
    const triggerBottom = useMemo(() => (triggerBoundingBox && window.innerHeight - (triggerBoundingBox === null || triggerBoundingBox === void 0 ? void 0 : triggerBoundingBox.top)) || 0, [triggerBoundingBox]);
    const topHeight = useMemo(() => window.innerHeight - triggerTop - 20, [triggerTop]);
    const bottomHeight = useMemo(() => window.innerHeight - triggerBottom - 20, [triggerBottom]);
    const isTop = useMemo(() => {
        return topHeight > bottomHeight;
    }, [topHeight, bottomHeight]);
    const contentMaxHeight = useMemo(() => {
        return isTop ? topHeight : bottomHeight;
    }, [topHeight, bottomHeight, isTop]);
    const valueArray = useMemo(() => JSON.parse(value || '[]'), [value]);
    const customValues = useMemo(() => {
        return valueArray.filter((value) => !options.find((option) => option.value === value));
    }, [options, valueArray]);
    // methods
    const handleCheckboxChange = useCallback((value, optionValue) => {
        if (value) {
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, optionValue]));
        }
        else {
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify(valueArray.filter((v) => v !== optionValue)));
        }
    }, [valueArray, onChange]);
    const handleChipPress = useCallback((value) => {
        const index = valueArray.indexOf(value);
        if (index === -1) {
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, value]));
        }
        else {
            const newValue = [...valueArray];
            newValue.splice(index, 1);
            onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify(newValue));
        }
    }, [valueArray, onChange]);
    const handleSaveCustomValue = useCallback(() => {
        // check if value already exists in options
        const option = options.find((option) => option.label === customValue);
        if (option) {
            handleChipPress(option.value);
        }
        else {
            if (customValue.length !== 0 && !valueArray.includes(customValue)) {
                onChange === null || onChange === void 0 ? void 0 : onChange(JSON.stringify([...valueArray, customValue]));
            }
        }
        setIsAddAnother(false);
        setCustomValue('');
    }, [valueArray, customValue, onChange, setIsAddAnother, setCustomValue]);
    // effects
    useEffect(() => {
        // check if length of selectedRef.current is greater than valueRef.current
        // if it is, set isShowAdditional to true, and count all of the widths of
        // the children of selectedRef.current to determine how many chips can fit,
        // then subtract that from the total number of chips to determine how many
        // additional chips there are
        if (!selectedRef.current || !valueRef.current)
            return;
        const valueWidth = selectedRef.current.getBoundingClientRect().width;
        const triggerWidth = valueRef.current.getBoundingClientRect().width;
        if (valueWidth <= triggerWidth - 39) {
            setNumAdditional(0);
            return;
        }
        let total = 0;
        let childrenWidth = 0;
        for (let i = 0; i < selectedRef.current.children.length; i++) {
            const child = selectedRef.current.children[i];
            childrenWidth += child.getBoundingClientRect().width;
            if (childrenWidth > triggerWidth - 39)
                break;
            total++;
        }
        setNumAdditional(valueArray.length - total);
    }, [valueArray, selectedRef, valueRef]);
    // create portal element when isOpen is true
    useEffect(() => {
        if (isOpen && !elPortal) {
            const portal = document.createElement('div');
            portal.id = 'portal';
            portal.style.position = 'fixed';
            portal.style.top = '0';
            portal.style.left = '0';
            portal.style.width = '100%';
            portal.style.height = '100%';
            portal.style.zIndex = '9999';
            portal.style.pointerEvents = 'none';
            document.body.appendChild(portal);
            setElPortal(portal);
        }
        else if (!isOpen && elPortal) {
            document.body.removeChild(elPortal);
            setElPortal(null);
        }
    }, [isOpen, elPortal]);
    return (_jsx(BaseField, Object.assign({ id: id, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(MultiSelectWrapper, { children: [_jsxs(MultiSelectTrigger, Object.assign({ onClick: () => setIsOpen(!isOpen), ref: triggerRef, "$isDisabled": isDisabled }, { children: [_jsx(ValueWrapper, Object.assign({ ref: valueRef }, { children: _jsx(SelectedWrapper, Object.assign({ ref: selectedRef }, { children: valueArray
                                    .filter((x, i) => i < valueArray.length - numAdditional)
                                    .map((value) => {
                                    var _a;
                                    return (_jsx(Chip, { value: value, label: ((_a = options.find((option) => option.value === value)) === null || _a === void 0 ? void 0 : _a.label) ||
                                            value ||
                                            '', size: 'sm', onPress: () => handleChipPress(value), isSelected: true }, value));
                                }) })) })), _jsxs(EndWrapper, { children: [_jsx(AdditionalWrapper, { children: numAdditional > 0 && (_jsx(Chip, { value: `additional`, label: `+${numAdditional}`, size: 'sm', isDisabled: true })) }), _jsx(IconWrapper, { children: _jsx(Icon, { icon: isOpen ? 'chevron-up' : 'chevron-down', fontSize: 16 }) })] })] })), isOpen &&
                    elPortal &&
                    createPortal(_jsxs(MultiSelectContent, Object.assign({ ref: contentRef, "$top": isTop ? triggerTop : null, "$bottom": isTop ? null : triggerBottom, "$left": (triggerBoundingBox === null || triggerBoundingBox === void 0 ? void 0 : triggerBoundingBox.left) || 0, "$width": (triggerBoundingBox === null || triggerBoundingBox === void 0 ? void 0 : triggerBoundingBox.width) || 300, "$maxHeight": isTop ? topHeight : bottomHeight }, { children: [_jsxs(CardContainer, Object.assign({ maxColumns: 2 }, { children: [options
                                        .sort((a, b) => (a.order || 0) - (b.order || 0))
                                        .map((option) => (_jsx(Checkbox, { id: `${id}-${option.value}`, label: option.label, value: !!valueArray.includes(option.value), onChange: (val) => handleCheckboxChange(val, option.value) }, option.value))), customValues.map((value) => (_jsx(Checkbox, { id: value, label: value, value: true, onChange: (val) => handleChipPress(value) }, value)))] })), isCustom && (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsxs(AddAnotherWrapper, { children: [isAddAnother && (_jsx(Input, { ariaLabel: 'add another', placeholder: 'Add custom', value: customValue, onChange: (val) => setCustomValue(val || ''), autoFocus: true })), _jsxs(ActionWrapper, { children: [!isAddAnother && (_jsx(Button, Object.assign({ ariaLabel: 'add another', size: 'sm', variant: 'secondary', iconLeft: 'plus', onPress: () => setIsAddAnother(true) }, { children: "Add another" }))), isAddAnother && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ ariaLabel: 'save', variant: 'secondary', size: 'sm', type: 'submit', onPress: handleSaveCustomValue }, { children: "Save" })), _jsx(Button, Object.assign({ ariaLabel: 'cancel', variant: 'link', size: 'sm', onPress: () => {
                                                                    setIsAddAnother(false);
                                                                    setCustomValue('');
                                                                } }, { children: "Cancel" }))] }))] })] })] }))] })), elPortal), isOpen && _jsx(Backdrop, { onClick: () => setIsOpen(false) })] }) })));
};
const MultiSelectWrapper = styled.div `
  position: relative;
`;
const MultiSelectTrigger = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  min-width: 100px;

  border-radius: 8px;
  width: 100%;
  font: ${(props) => props.theme.typography.body.md.regular};
  background: ${(props) => props.theme.color.neutral[10]};
  color: ${(props) => props.theme.color.neutral[90]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  padding: ${(props) => props.theme.spacing[12]};
  cursor: pointer;

  min-height: 48px;
  max-height: 48px;

  &:hover {
    background: ${(props) => props.theme.color.neutral[20]};
  }

  &:focus {
    outline: 3px solid ${(props) => props.theme.color.primary[20]};
  }

  ${(props) => props.$isDisabled &&
    css `
      background: ${(props) => props.theme.color.neutral[30]};
      color: ${(props) => props.theme.color.neutral[50]};
      pointer-events: none;
    `}
`;
const ValueWrapper = styled.div `
  display: flex;
  width: 100%;
`;
const SelectedWrapper = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
`;
const AdditionalWrapper = styled.div ``;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const MultiSelectContent = styled.div `
  display: flex;

  pointer-events: all;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  position: absolute;
  top: ${(props) => props.$top}px;
  bottom: ${(props) => props.$bottom}px;
  left: ${(props) => props.$left}px;
  width: 100%;
  max-width: ${(props) => props.$width}px;
  max-height: ${(props) => props.$maxHeight}px;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[3]};
  border-radius: 8px;
  overflow: hidden;
  overflow-y: auto;
  margin: ${(props) => props.theme.spacing[4]} 0;
  padding: ${(props) => props.theme.spacing[16]}
    ${(props) => props.theme.spacing[20]};
  z-index: 20;
`;
const EndWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Backdrop = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
`;
const AddAnotherWrapper = styled.form `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[16]};
`;
const ActionWrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Divider = styled.div `
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.neutral[40]};
`;
MultiSelect.componentName = 'MultiSelect';
export default MultiSelect;
