// react
import { useCallback } from 'react';

// components
import { CareSearch as CareSearchPage } from '@karehero/llama';

// views
import Directory from 'directory/Directory';

// store
import { useSelector } from 'store';
import {
  useGetShortlistByCareProfileIdQuery,
  useGetShortlistDataByCareProfileIdQuery,
  useMarkShortlistCareHomeSecuredByCareProfileIdMutation,
} from 'store/api/shortlist';
import { useGetCareCircleQuery } from 'store/api/careCircle';
import { getCurrentCareCircleId } from 'store/slices/careCircleSlice';

/**
 * AdminCareSearch shows a search bar and a list of care homes based on the user's search criteria and shortlist.
 */
const AdminCareSearch = () => {
  // hooks
  const careCircleId = useSelector(getCurrentCareCircleId);

  const { data: currentCareCircle } = useGetCareCircleQuery(careCircleId, {
    skip: !careCircleId,
  });
  const { data: careHomeShortlistData } =
    useGetShortlistDataByCareProfileIdQuery(
      currentCareCircle?.careProfile?.id || '',
      { skip: !currentCareCircle },
    );
  const { data: careHomeShortlist } = useGetShortlistByCareProfileIdQuery(
    currentCareCircle?.careProfile?.id || '',
    { skip: !currentCareCircle },
  );
  const [markCareHomeSecured] =
    useMarkShortlistCareHomeSecuredByCareProfileIdMutation();

  // callbacks
  const markSecured = useCallback(
    (regulatoryID: string) => {
      return (
        careHomeShortlist &&
        markCareHomeSecured({
          ...careHomeShortlist,
          securedRegulatoryID: regulatoryID,
        })
      );
    },
    [careHomeShortlist, markCareHomeSecured],
  );

  return (
    <>
      {careHomeShortlist && careHomeShortlistData && (
        <CareSearchPage
          shortlist={careHomeShortlist}
          shortlistData={careHomeShortlistData}
          onMarkSecured={markSecured}
        />
      )}
      <Directory />
    </>
  );
};

export default AdminCareSearch;
