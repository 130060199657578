var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// radix
import * as RadixLabel from '@radix-ui/react-label';
/**
 * Label is a small header for form components.
 */
export const Label = (_a) => {
    var { className, id, text, isRequired } = _a, props = __rest(_a, ["className", "id", "text", "isRequired"]);
    return (_jsxs(StyledLabelRoot, Object.assign({ className: className, htmlFor: id }, props, { children: [text, _jsx(StyledIsRequired, { children: isRequired && '*' })] })));
};
const StyledLabelRoot = styled(RadixLabel.Root) `
  // default styles
  display: flex;
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.medium};
`;
const StyledIsRequired = styled.span `
  margin-left: ${(props) => props.theme.spacing[2]};
  color: ${(props) => props.theme.color.danger[60]};
`;
Label.componentName = 'Label';
export default Label;
