import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button, Container, Keni } from "../../atoms";
/**
 * CarePlanComplete shows the care plan is complete.
 */
export const CarePlanComplete = ({ className, to }) => {
    return (_jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsx(Title, { children: "You are done now!" }), _jsx(SubTitle, { children: "Great, you have created and sent the new care assessment!" }), _jsx(SubSubTitle, { children: "Once approved, you will recieve and email and will be able to start your research." }), _jsxs("div", { children: [_jsx(SubTitle, { children: "Important" }), _jsx(Paragraph, { children: "Never put sugar in tea! It ruins the flavour and is just not the done thing! If you take anything away from this KareHero form let it be this!" })] }), _jsx(Button, Object.assign({ ariaLabel: 'manage care circles', variant: 'primary', to: to }, { children: "Manage Care Circles" })), _jsx(Keni, {})] }) })));
};
const StyledContainer = styled(Container) `
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  margin-top: ${(props) => props.theme.spacing[64]};
  gap: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['4xl']};
  color: ${(props) => props.theme.color.primary[80]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const SubSubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Paragraph = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CarePlanComplete.displayName = 'CarePlanComplete';
export default CarePlanComplete;
