import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Button, Card, Chip, Divider, Icon } from "../../atoms";
import { ShortlistProgress } from "../../organisms";
// fixtures
import { shortlistItems } from "../../organisms/ShortlistProgress/fixtures";
// assets
// @transform-path ../../../../../../src/assets/app/header.svg
import defaultHeader from "../../../../../../src/assets/app/header.svg";
export const CareHomeCard = ({ regulatoryID = '', headerImageUrl = '', name = '', address = '', rating = '', phone = '', notes = '', careServiceType = [], available = false, isSecured = false, onMarkSecured, }) => {
    // helper functions
    const getIconForRating = (rating) => {
        const normalizedRating = rating.toLowerCase();
        switch (normalizedRating) {
            case 'good':
            case 'requires improvement':
            case 'inadequate':
                return { icon: 'circle', type: 'fas' };
            case 'registered - awaiting online inspection':
                return { icon: 'circle', type: 'fal' };
            case 'outstanding':
                return { icon: 'star', type: 'fas' };
            default:
                return { icon: 'circle', type: 'fas' };
        }
    };
    const formattedRating = `${rating.charAt(0).toUpperCase() + rating.slice(1)}`;
    const iconConfig = getIconForRating(rating);
    return (_jsxs(CardLink, Object.assign({ to: `/directory?provider=${regulatoryID}` }, { children: [_jsxs(CardHeader, { children: [_jsx("img", { src: headerImageUrl || defaultHeader, alt: 'Service Header' }), available && (_jsx(AvailabilityChipContainer, { children: _jsx(Chip, { label: 'Rooms Available' }, 'rooms-available') }))] }), _jsxs(CardBody, { children: [_jsxs(CardDetails, { children: [_jsx(ServiceName, { children: name }), _jsx(ServiceAddress, { children: address }), _jsx(ServiceTypeChips, { children: careServiceType
                                    .filter((service) => !!service.name)
                                    .map((service) => (_jsx(Chip, { label: service.name || '' }, service.id))) }), _jsxs(RatingSection, { children: [_jsx(RatingIcon, { icon: iconConfig.icon, fontSize: 16, type: iconConfig.type, rating: rating }), _jsxs(RatingText, { children: [_jsx(BoldRating, { children: formattedRating }), " - Regulator's Rating"] })] })] }), _jsx(ShortlistProgress, { shortlistItems: shortlistItems }), _jsx(CardActions, { children: _jsxs(ExpertNoteContainer, { children: [_jsxs(NoteHeader, { children: [_jsx(Icon, { icon: 'edit', size: 'lg', fontSize: 14 }), _jsx(NoteTitle, { children: "Care Expert Note" })] }), _jsx(NoteContent, { children: notes || 'No Note Available' })] }) })] }), _jsxs(CardFooter, { children: [_jsx(Divider, { isOr: false }), phone && (_jsxs(Button, Object.assign({ to: `tel:${phone}`, ariaLabel: 'Call Service', variant: 'secondary' }, { children: [_jsx(Icon, { icon: 'phone', fontSize: 16 }), _jsx(PhoneNumber, Object.assign({ href: `tel:${phone}` }, { children: phone }))] }))), _jsx(Button, Object.assign({ ariaLabel: isSecured ? 'Service Secured' : 'Mark as Secured', onPress: onMarkSecured, isFullWidth: true, isPreventDefault: true }, { children: isSecured ? 'Secured' : 'I Have Secured This Service' })), _jsxs(GuidanceLabel, { children: [_jsx(Icon, { icon: 'info-circle', fontSize: 14 }), " Confirm to receive guidance on managing care effectively"] })] })] })));
};
const CardBody = styled(Card) `
  gap: 0;
  border: 0;
  background: none;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[32]};
  margin: ${(props) => props.theme.spacing[2]};
  box-shadow: none;
`;
const CardDetails = styled.div `
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[40]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const CardHeader = styled.div `
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    height: 200px;
    max-height: 200px;
  }
`;
const CardActions = styled.div `
  display: flex;
  margin-top: auto;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[16]};
`;
const ServiceName = styled.div `
  color: ${(props) => props.theme.color.neutral[100]};
  font: ${(props) => props.theme.typography.body.xl.semibold};
`;
const CardLink = styled(Link) `
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  border-radius: 8px;
  max-height: fit-content;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.02);
  }
  flex: 1;
`;
const ServiceAddress = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const ServiceTypeChips = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[8]};
`;
const RatingSection = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const RatingText = styled.div `
  display: inline;
  margin-left: ${(props) => props.theme.spacing[8]};
`;
const BoldRating = styled.span `
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
const RatingIcon = styled(Icon) `
  color: ${(props) => {
    switch (props.rating.toLowerCase()) {
        case 'inadequate':
            return props.theme.color.danger[50];
        case 'registered - awaiting online inspection':
            return props.theme.color.neutral[100];
        case 'requires improvement':
            return props.theme.color.warning[40];
        case 'outstanding':
        case 'good':
            return props.theme.color.success[40];
        default:
            return props.theme.color.neutral[40];
    }
}};
`;
const GuidanceLabel = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const AvailabilityChipContainer = styled.div `
  position: absolute;
  top: ${(props) => props.theme.spacing[8]};
  left: ${(props) => props.theme.spacing[8]};
`;
const PhoneNumber = styled.a `
  display: inline;
  text-decoration: underline;
  color: ${(props) => props.theme.color.primary[80]};
  padding-left: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
const NoteContent = styled.div `
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing[8]};
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const ExpertNoteContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
const NoteHeader = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
const NoteTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
const CardFooter = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 ${(props) => props.theme.spacing[32]}
    ${(props) => props.theme.spacing[32]};
  gap: ${(props) => props.theme.spacing[16]};
  flex: 1;
`;
CareHomeCard.displayName = 'CareHomeCard';
export default CareHomeCard;
