// react
import { useMemo } from 'react';

// models
import { MemberRole } from '@karehero/models';

// hooks
import { useCareCircle } from 'hooks';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

interface Permission {
  isRead: boolean;
  isModify: boolean;
}

export const usePermissions = () => {
  // hooks
  const { currentCareCircleMember } = useCareCircle();
  const account = useSelector(getAccount);

  // memos
  const isPrimaryCaregiver = useMemo((): boolean => {
    if (account?.accountRole?.name === 'KareHero Administrator') return true;
    if (!currentCareCircleMember?.roles) return false;

    return currentCareCircleMember.roles.some(
      (role) => role.name === MemberRole.PrimaryCaregiver,
    );
  }, [currentCareCircleMember, account]);

  const actionPlan = useMemo(
    (): Permission => ({
      isRead: true,
      isModify: isPrimaryCaregiver,
    }),
    [isPrimaryCaregiver],
  );

  const attendanceAllowance = useMemo(
    (): Permission => ({
      isRead: true,
      isModify: isPrimaryCaregiver,
    }),
    [isPrimaryCaregiver],
  );

  const financialStatus = useMemo(
    (): Permission => ({
      isRead: true,
      isModify: isPrimaryCaregiver,
    }),
    [isPrimaryCaregiver],
  );

  const bookCall = useMemo(
    (): Permission => ({
      isRead: true,
      isModify: isPrimaryCaregiver,
    }),
    [isPrimaryCaregiver],
  );

  const careCircle = useMemo(
    (): Permission => ({
      isRead: true,
      isModify: isPrimaryCaregiver,
    }),
    [isPrimaryCaregiver],
  );

  const carePlan = useMemo(
    (): Permission => ({
      isRead: true,
      isModify: isPrimaryCaregiver,
    }),
    [isPrimaryCaregiver],
  );

  return {
    actionPlan,
    attendanceAllowance,
    financialStatus,
    bookCall,
    careCircle,
    carePlan,
  };
};
