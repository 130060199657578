import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Button, Card, Container, Divider, Icon, ProgressBar, } from "../../atoms";
import { BackButton, FloatingActions } from "../../molecules";
// fixture
import { SuccessContent } from "../../organisms";
export const FinancialStatusComplete = ({ clientName = 'Your Loved One', toFundingGuide, onPrintPdf, onOpenSections, }) => {
    return (_jsxs(PageWrapper, { children: [_jsxs(TopRow, { children: [_jsx(BackButton, { isNoMargin: true }), _jsxs(PrintWrapper, { children: [_jsx(Button, Object.assign({ ariaLabel: 'print', variant: 'link', onPress: onPrintPdf, iconLeft: 'print', size: 'sm' }, { children: "Print" })), _jsx(Button, Object.assign({ ariaLabel: 'sections', variant: 'link', onPress: onOpenSections, iconLeft: 'list', size: 'sm' }, { children: "Sections" }))] })] }), _jsx(Container, Object.assign({ size: 'xl' }, { children: _jsxs(ContentWrapper, { children: [_jsxs(ProgressHeader, { children: [clientName, "'s Financial Status"] }), _jsx(ProgressBar, { label: 'Progress', progress: 100, limit: 100 }), _jsx(Divider, { isOr: false }), _jsx(SuccessContent, { header: 'Financial Status Complete', cardHeader: 'Now Review Your Answers', fixtures: [
                                {
                                    icon: 'check-circle',
                                    text: 'Review your Funding Consultation answers to ensure all the needs captured are correct',
                                    iconColor: 'success',
                                },
                                {
                                    icon: 'user',
                                    text: 'Once submitted you can chat to your Care Expert regarding your Funding Consultation',
                                    iconColor: 'blue',
                                },
                                {
                                    icon: 'file-contract',
                                    text: 'Your Care Expert will now provide you with your Funding Consultation',
                                    iconColor: 'primary',
                                },
                            ] }), _jsx(FloatingActions, { direction: 'col', actions: [
                                {
                                    label: 'Submit Form',
                                    to: toFundingGuide,
                                },
                            ] })] }) }))] }));
};
const PageWrapper = styled.div `
  padding: ${(props) => props.theme.spacing[24]};
  padding-bottom: ${(props) => props.theme.spacing[96]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const PrintWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: ${(props) => props.theme.spacing[16]};
`;
const ProgressHeader = styled.div `
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['3xl']};
  text-align: center;
`;
const Header = styled(ProgressHeader) `
  color: ${(props) => props.theme.color.neutral[100]};
`;
const ContentHeader = styled(ProgressHeader) `
  text-align: left;
  color: ${(props) => props.theme.color.neutral[90]};
`;
const TopRow = styled.div `
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const ContentCard = styled(Card) `
  padding: ${(props) => props.theme.spacing[32]};
`;
const ColoredIcon = styled(Icon) `
  color: ${(props) => props.iconColor === 'orange'
    ? props.theme.color.primary[30]
    : props.iconColor === 'blue'
        ? props.theme.color.actionPlanCards.blue.primary
        : props.iconColor === 'success'
            ? props.theme.color.success[40]
            : props.theme.color.primary[70]};
`;
const TextCell = styled.td `
  font: ${(props) => props.theme.typography.body.md.regular};
  padding: ${(props) => props.theme.spacing[12]} 0;
`;
const IconCell = styled.td `
  padding-right: ${(props) => props.theme.spacing[12]};
`;
FinancialStatusComplete.displayName = 'FinancialStatusComplete';
export default FinancialStatusComplete;
