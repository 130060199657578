// react
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import {
  CareProfileForm as CareProfileFormPage,
  CareProfileRoot,
} from '@karehero/llama';

// hooks
import { useCareProfile, usePermissions } from 'hooks';

// pdf
import { careProfileDocDefinition } from 'pdf';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// store
import { useLazyGetAllCategoryQuery } from 'store/api/careProfile';
import { CareProfileAnswer } from '@karehero/models';

const FinancialStatusForm = () => {
  // hooks
  const {
    forms,
    numCompleted,
    numTotal,
    careCircle,
    careProfileFormId,
    careProfileCategories,
    answers,
    sections,
    setAnswers,
    createManyAnswer,
  } = useCareProfile({ categoryID: 'financial-status' });
  const navigate = useNavigate();
  const { track } = useMixpanel();
  const { financialStatus } = usePermissions();

  // store
  const [getAllCategory] = useLazyGetAllCategoryQuery();

  // handlers
  const trackFormViewed = useCallback(() => {
    track(MixpanelEvent.FinancialStatusViewed, {
      carePlanFormId: careProfileFormId,
    });
  }, [careProfileFormId, track]);

  const trackFormSaved = useCallback(() => {
    track(MixpanelEvent.FinancialStatusSaved, {
      carePlanFormId: careProfileFormId,
    });
  }, [careProfileFormId, track]);

  // memos
  const careRecipientName = useMemo(
    () =>
      `${careCircle?.careRecipientAccount?.firstName || ''} ${
        careCircle?.careRecipientAccount?.lastName || ''
      }`,
    [careCircle],
  );

  const toPrevious = useMemo(() => {
    if (!forms || forms.length === 0) return '/care-profile?tab=3';

    const currentIndex = forms.findIndex(
      (form) => form.id === careProfileFormId,
    );
    if (currentIndex <= 0) return '/care-profile?tab=3';

    const prevFormId = forms[currentIndex - 1]?.id;
    return prevFormId
      ? `/financial-status/${prevFormId}`
      : '/care-profile?tab=3';
  }, [forms, careProfileFormId]);

  // methods
  const handlePrintPdf = useCallback(() => {
    if (!careProfileCategories) return;
    careProfileDocDefinition(
      careProfileCategories,
      answers,
      careRecipientName,
      'financial-status',
      'Financial Status',
    ).open();
  }, [careProfileCategories, answers, careRecipientName]);

  const toNext = useCallback(() => {
    const nextFormId = forms?.reduce((acc, form, index, array) => {
      if (form.id === careProfileFormId && index < array.length - 1) {
        return array[index + 1].id;
      }
      return acc;
    }, null);

    navigate(
      nextFormId === null
        ? `/financial-status/complete`
        : `/financial-status/${nextFormId}`,
    );
  }, [navigate, forms, careProfileFormId]);

  const saveAnswers = useCallback(() => {
    const careProfileAnswers: CareProfileAnswer[] = [];
    Object.keys(answers).forEach((form) => {
      Object.keys(answers[form]).forEach((key) => {
        if (!careCircle?.careProfile?.id) return;

        careProfileAnswers.push({
          careProfileId: careCircle.careProfile.id,
          careProfileQuestionId: key,
          value: answers[form][key],
        });
      });
    });

    createManyAnswer(careProfileAnswers);
  }, [answers, careCircle, createManyAnswer]);

  const handleSave = useCallback(() => {
    saveAnswers();
    trackFormSaved();
    toNext();
  }, [saveAnswers, toNext, trackFormSaved]);

  // effects
  useEffect(() => {
    getAllCategory();
    trackFormViewed();
  }, [getAllCategory, trackFormViewed]);

  return (
    <CareProfileRoot
      nextLabel='Save and Continue'
      limit={numTotal}
      progress={numCompleted}
      onPrintPdf={handlePrintPdf}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      title={`${
        careCircle?.careRecipientAccount?.firstName || 'Your Loved One'
      }'s Financial Status`}
      toExit='/care-profile?tab=3'
      onNext={handleSave}
      toPrevious={toPrevious}
      isFormCompleted={true}
    >
      <CareProfileFormPage
        form={forms?.find((form) => form.id === careProfileFormId)}
        value={answers}
        onChange={setAnswers}
        careRecipientName={careCircle?.careRecipientAccount?.firstName}
        isDisabled={financialStatus.isModify}
      />
    </CareProfileRoot>
  );
};

export default FinancialStatusForm;
