// fontawesome
import { library, } from '@fortawesome/fontawesome-svg-core';
// icons - find icons at https://fontawesome.com/search
import { faArrowCircleDown, faArrowCircleUp, faArrowDownToBracket, faArrowRightFromBracket, faArrowsRotate, faBadgeCheck, faBell, faBook, faBookHeart, faBookmark, faBookOpen, faBuilding, faCalendarCheck, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faChevronRight, faChevronsDown, faChevronsLeft, faChevronsRight, faChevronsUp, faChevronUp, faCircleHeart, faCirclePlus, faCircleQuestion, faCircleSterling, faClipboard, faClipboardCheck, faClipboardList, faClock, faCircle, faCopy, faDiscDrive, faDownload, faEdit, faEnvelope, faEnvelopeOpenText, faEnvelopes, faExclamationCircle, faExclamationTriangle, faEye, faFileContract, faFilePlus, faFlag, faFolderOpen, faGear, faHandHolding, faHandHoldingHeart, faHeadset, faHeart, faHeartPulse, faHome, faHorizontalRule, faHouse, faHouseBlank, faInfoCircle, faKitMedical, faList, faListCheck, faLocationArrow, faLocationDot, faLongArrowLeft, faLongArrowRight, faMagnifyingGlassLocation, faMailbox, faMemo, faMessage, faMinus, faMoneyBills, faMoneyCheckPen, faPaperPlaneTop, faPartyHorn, faPenField, faPhone, faPlay, faPlus, faPlusSquare, faPrint, faQuestionCircle, faRotateRight, faRoute, faScaleBalanced, faSearch, faSitemap, faSmile, faSpa, faStar, faSterlingSign, faTimes, faTrash, faTruckFast, faTv, faUpload, faUser, faUserGroup, faUsers, faVideo, faXmark, } from '@fortawesome/pro-light-svg-icons';
import { faBookmark as faBookmarkSolid, faCheck as faCheckSolid, faCheckCircle as faCheckCircleSolid, faChevronLeft as faChevronLeftSolid, faChevronRight as faChevronRightSolid, faCircleXmark as faCircleXmarkSolid, faHandHoldingHeart as faHandHoldingHeartSolid, faHouseBlank as faHouseBlankSolid, faListCheck as faListCheckSolid, faMagnifyingGlassLocation as faMagnifyingGlassLocationSolid, faMessage as faMessageSolid, faPaperPlaneTop as faPaperPlaneTopSolid, faXmark as faXmarkSolid, faCircle as faCircleSolid, faStar as faStarSolid, } from '@fortawesome/pro-solid-svg-icons';
import { faApple, faFacebook, faGoogle, } from '@fortawesome/free-brands-svg-icons';
export const iconTypes = {
    faIcons: {
        'exclamation-triangle': faExclamationTriangle,
        'arrow-circle-down': faArrowCircleDown,
        'arrow-circle-up': faArrowCircleUp,
        'arrow-down-to-bracket': faArrowDownToBracket,
        'arrows-rotate': faArrowsRotate,
        'badge-check': faBadgeCheck,
        bell: faBell,
        book: faBook,
        bookmark: faBookmark,
        'book-open': faBookOpen,
        building: faBuilding,
        'circle-sterling': faCircleSterling,
        check: faCheck,
        circle: faCircle,
        'check-circle': faCheckCircle,
        'chevron-down': faChevronDown,
        'chevron-left': faChevronLeft,
        'chevron-right': faChevronRight,
        'chevron-up': faChevronUp,
        'chevrons-down': faChevronsDown,
        'chevrons-left': faChevronsLeft,
        'chevrons-right': faChevronsRight,
        'chevrons-up': faChevronsUp,
        clipboard: faClipboard,
        'clipboard-check': faClipboardCheck,
        'clipboard-list': faClipboardList,
        'circle-heart': faCircleHeart,
        'circle-plus': faCirclePlus,
        'circle-question': faCircleQuestion,
        copy: faCopy,
        'disc-drive': faDiscDrive,
        download: faDownload,
        edit: faEdit,
        eye: faEye,
        'book-heart': faBookHeart,
        'file-contract': faFileContract,
        'file-plus': faFilePlus,
        flag: faFlag,
        gear: faGear,
        'hand-holding': faHandHolding,
        'hand-holding-heart': faHandHoldingHeart,
        headset: faHeadset,
        heart: faHeart,
        'heart-pulse': faHeartPulse,
        home: faHome,
        house: faHouse,
        'house-blank': faHouseBlank,
        'info-circle': faInfoCircle,
        'kit-medical': faKitMedical,
        list: faList,
        'list-check': faListCheck,
        'location-arrow': faLocationArrow,
        'long-arrow-left': faLongArrowLeft,
        'long-arrow-right': faLongArrowRight,
        'arrow-right-from-bracket': faArrowRightFromBracket,
        'magnifying-glass-location': faMagnifyingGlassLocation,
        memo: faMemo,
        minus: faMinus,
        'money-bills': faMoneyBills,
        message: faMessage,
        'paper-plane-top': faPaperPlaneTop,
        'party-horn': faPartyHorn,
        phone: faPhone,
        play: faPlay,
        plus: faPlus,
        'plus-square': faPlusSquare,
        print: faPrint,
        'rotate-right': faRotateRight,
        route: faRoute,
        'scale-balanced': faScaleBalanced,
        search: faSearch,
        sitemap: faSitemap,
        smile: faSmile,
        spa: faSpa,
        star: faStar,
        'sterling-sign': faSterlingSign,
        times: faTimes,
        trash: faTrash,
        tv: faTv,
        upload: faUpload,
        user: faUser,
        users: faUsers,
        xmark: faXmark,
        'exclamation-circle': faExclamationCircle,
        'question-circle': faQuestionCircle,
        mailbox: faMailbox,
        envelope: faEnvelope,
        envelopes: faEnvelopes,
        'envelope-open-text': faEnvelopeOpenText,
        'pen-field': faPenField,
        'truck-fast': faTruckFast,
        'money-check-pen': faMoneyCheckPen,
        'folder-open': faFolderOpen,
        'calendar-check': faCalendarCheck,
        'horizontal-rule': faHorizontalRule,
        clock: faClock,
        'location-dot': faLocationDot,
        video: faVideo,
        'user-group': faUserGroup,
    },
    faIconsSolid: {
        bookmark: faBookmarkSolid,
        check: faCheckSolid,
        circle: faCircleSolid,
        'check-circle': faCheckCircleSolid,
        'circle-xmark': faCircleXmarkSolid,
        'chevron-left': faChevronLeftSolid,
        'chevron-right': faChevronRightSolid,
        'paper-plane-top': faPaperPlaneTopSolid,
        xmark: faXmarkSolid,
        'house-blank': faHouseBlankSolid,
        'list-check': faListCheckSolid,
        'hand-holding-heart': faHandHoldingHeartSolid,
        'magnifying-glass-location': faMagnifyingGlassLocationSolid,
        message: faMessageSolid,
        star: faStarSolid,
    },
    faIconsBrands: {
        apple: faApple,
        facebook: faFacebook,
        google: faGoogle,
    },
};
// retrieve a list of all libraries
const iconLibraries = Object.keys(iconTypes).map((iconTypeKey) => {
    return iconTypes[iconTypeKey];
});
// add to library and export types
iconLibraries.forEach((iconLibrary) => {
    library.add(...Object.values(iconLibrary));
});
/**
 * validateIcon ensures the provided icon string is of type Icons, and returns
 * the 'star' icon as a default if it is not.
 */
export const validateIcon = (icon) => {
    let isIcon = false;
    iconLibraries.forEach((iconLibrary) => {
        if (icon in iconLibrary) {
            isIcon = true;
        }
    });
    if (!isIcon)
        return 'star';
    return icon;
};
