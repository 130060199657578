// react
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useCareProfile, usePermissions } from 'hooks';

// components
import {
  CareProfileForm as CareProfileFormPage,
  CareProfileRoot,
} from '@karehero/llama';

// pdf
import { careProfileDocDefinition } from 'pdf';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// store
import { useLazyGetAllCategoryQuery } from 'store/api/careProfile';
import { CareProfileAnswer } from '@karehero/models';

/**
 * CareProfileForm is the dashboard page.
 */
const CareProfileForm = () => {
  // hooks
  const { track } = useMixpanel();
  const [getAllCategory] = useLazyGetAllCategoryQuery();
  const { carePlan } = usePermissions();
  const {
    forms,
    numCompleted,
    numTotal,
    careCircle,
    careProfileFormId,
    answers,
    setAnswers,
    careProfileCategories,
    sections,
    createManyAnswer,
  } = useCareProfile({ categoryID: 'care-assessment' });
  const navigate = useNavigate();

  // memos
  const careRecipientName = useMemo(
    () =>
      `${careCircle?.careRecipientAccount?.firstName || ''} ${
        careCircle?.careRecipientAccount?.lastName || ''
      }`,
    [careCircle],
  );

  // handlers
  const handleSave = useCallback(() => {
    const careProfileAnswers: CareProfileAnswer[] = [];
    Object.keys(answers).forEach((form) => {
      Object.keys(answers[form]).forEach((key) => {
        if (!careCircle?.careProfile?.id) {
          return;
        }
        careProfileAnswers.push({
          careProfileId: careCircle.careProfile.id,
          careProfileQuestionId: key,
          value: answers[form][key],
        });
      });
    });

    createManyAnswer(careProfileAnswers);

    // get next form
    let nextFormId: string | null = null;
    let isNext = false;
    forms?.forEach((form) => {
      if (isNext) {
        nextFormId = form.id;
        isNext = false;
        return;
      }
      if (form.id === careProfileFormId) {
        isNext = true;
        return;
      }
    });
    if (nextFormId === null) {
      navigate(`/care-assessment/complete`);
      return;
    }
    navigate(`/care-assessment/${nextFormId}`);
  }, [
    navigate,
    answers,
    careCircle,
    createManyAnswer,
    forms,
    careProfileFormId,
  ]);

  const toPrevious = useMemo(() => {
    // get previous form
    let prevFormId: string | null = null;
    for (let i = 0; i < (forms?.length || 0); i++) {
      const form = forms?.[i];
      if (form?.id === careProfileFormId) {
        if (i === 0) {
          continue;
        }
        prevFormId = forms?.[i - 1]?.id || null;
        continue;
      }
    }
    return prevFormId === null
      ? '/care-assessment?tab=2'
      : `/care-assessment/${prevFormId}`;
  }, [forms, careProfileFormId]);

  const handlePrintPdf = useCallback(() => {
    if (!careProfileCategories) return;
    careProfileDocDefinition(
      careProfileCategories,
      answers,
      careRecipientName,
    ).open();
  }, [careProfileCategories, answers, careRecipientName]);

  // effects
  useEffect(() => {
    getAllCategory();
  }, [getAllCategory]);

  useEffect(() => {
    track(MixpanelEvent.CarePlanFormViewed, {
      carePlanFormId: careProfileFormId,
    });
  }, [careProfileFormId, track]);

  return (
    <CareProfileRoot
      nextLabel='Save & Continue'
      onPrintPdf={handlePrintPdf}
      limit={numTotal}
      progress={numCompleted}
      sections={sections}
      name={careCircle?.careRecipientAccount?.firstName}
      toExit='/care-assessment?tab=2'
      onNext={handleSave}
      toPrevious={toPrevious}
      isFormCompleted={true}
    >
      <CareProfileFormPage
        form={forms?.find((form) => form.id === careProfileFormId)}
        value={answers}
        onChange={setAnswers}
        careRecipientName={careCircle?.careRecipientAccount?.firstName}
        isDisabled={carePlan.isModify}
      />
    </CareProfileRoot>
  );
};

export default CareProfileForm;
