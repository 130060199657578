var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useMemo, useState } from 'react';
// components
import { Avatar, Button, Dialog, Loader, LoadingTransition, } from "../../atoms";
import { InviteClientForm, InviteEmailPreview, OnboardingComponent, } from "../../organisms";
import { OnboardingActionType, OnboardingFlow, TablePage, } from "..";
// tanstack
import { createColumnHelper } from '@tanstack/react-table';
// columns
const columnHelper = createColumnHelper();
const generateDefaultPersonalizedMessage = (clientName, partnerName) => {
    return `Hi ${clientName}, I'm sending over this invite as I think that you'll really benefit from KareHero. Thanks, ${partnerName}.`;
};
/**
 * ClientPortal shows the accounts and invited clients of an organization
 */
export const ClientPortal = ({ isLoading, organization, ownerAccount, accounts, emailTemplate, inviteClient, resendInvite, notifyToast, isBackButton = true, }) => {
    // state
    const [isValidationValid, setIsValidationValid] = useState(false);
    const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        referral: {},
        referrer: {},
    });
    // memos
    const defaultPersonalizedMessage = useMemo(() => {
        var _a;
        if (!ownerAccount || !((_a = formData.referral) === null || _a === void 0 ? void 0 : _a.firstName))
            return undefined;
        return generateDefaultPersonalizedMessage(formData.referral.firstName, `${ownerAccount.firstName}`);
    }, [formData, ownerAccount]);
    const isFormValid = useMemo(() => {
        return [
            'firstName',
            'lastName',
            'email',
            'phone',
            'personReceivingCare',
            'careSituationContext',
            'feePaid',
        ].every((x) => {
            var _a;
            const v = (_a = Object(formData.referral)) === null || _a === void 0 ? void 0 : _a[x];
            return v && v.length > 0;
            // TODO[referral]: update this logic once models are finalised.
        }) /* &&
          ['feePaid'].every((x) => {
            const v = Object(formData.referrer)?.[x];
            return v && v.length > 0;
          }) */;
    }, [formData.referral]);
    const columns = useMemo(() => [
        columnHelper.accessor('imgSrc', {
            header: '',
            cell: (info) => {
                var _a, _b, _c, _d, _e;
                const row = info.cell.row.original;
                let fallback = `${((_a = row.firstName) === null || _a === void 0 ? void 0 : _a[0]) || ''}${((_b = row.lastName) === null || _b === void 0 ? void 0 : _b[0]) || ''}`.toUpperCase();
                if (fallback.length === 0) {
                    if (row.email.length > 0) {
                        fallback = (_c = row.email[0]) === null || _c === void 0 ? void 0 : _c.toUpperCase();
                    }
                    else {
                        ((_e = (_d = row.inviteKey) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.toUpperCase()) || '';
                    }
                }
                return _jsx(Avatar, { size: 'xs', fallback: fallback });
            },
        }),
        columnHelper.accessor('firstName', {
            header: 'First Name',
        }),
        columnHelper.accessor('lastName', {
            header: 'Last Name',
        }),
        columnHelper.accessor('email', {
            header: 'Email Address',
        }),
        columnHelper.accessor('isRegistered', {
            header: 'Registered',
            cell: (info) => {
                return info.getValue() ? 'Yes' : 'No';
            },
        }),
    ], [organization]);
    const actions = useMemo(() => {
        return [
            {
                label: 'Reinvite',
                isHidden: (account) => { var _a; return !!account.isRegistered || (((_a = account.email) === null || _a === void 0 ? void 0 : _a.length) || 0) === 0; },
                onPress: (account) => {
                    if (account.id) {
                        resendInvite(account.id);
                        notifyToast({
                            title: 'Invitation Success',
                            description: `Resent invitation to ${account.email}`,
                        });
                    }
                },
            },
        ];
    }, [resendInvite, notifyToast]);
    const inviteFormConfig = useMemo(() => {
        var _a;
        return [
            {
                id: 'invite',
                Component: OnboardingComponent,
                config: [
                    {
                        id: 'invite-client-form',
                        component: (_jsx(InviteClientForm, { value: formData, onChange: (newData) => setFormData((prev) => (Object.assign(Object.assign({}, prev), newData))), setIsValid: setIsValidationValid })),
                        actions: [
                            {
                                label: 'Preview Email',
                                onClick: OnboardingActionType.Next,
                                iconRight: 'eye',
                                isDisabled: !isFormValid || !isValidationValid,
                            },
                        ],
                    },
                    {
                        id: 'invite-email-preview',
                        component: (_jsx(InviteEmailPreview, { emailTemplate: emailTemplate, defaultPersonalizedMessage: defaultPersonalizedMessage, personalizedMessage: formData.referral.personalizedMsg, setPersonalizedMessage: (personalizedMsg) => setFormData((prev) => (Object.assign(Object.assign({}, prev), { referral: Object.assign(Object.assign({}, prev.referral), { personalizedMsg }) }))), clientName: ((_a = formData.referral) === null || _a === void 0 ? void 0 : _a.firstName) || 'Hi', inviterName: `${ownerAccount === null || ownerAccount === void 0 ? void 0 : ownerAccount.firstName} ${ownerAccount === null || ownerAccount === void 0 ? void 0 : ownerAccount.lastName}` })),
                        actions: [
                            {
                                label: 'Back',
                                onClick: OnboardingActionType.Back,
                            },
                            {
                                label: 'Send Invite',
                                onClick: OnboardingActionType.Next,
                                iconRight: 'location-arrow',
                            },
                        ],
                    },
                ],
            },
        ];
    }, [
        formData,
        isFormValid,
        defaultPersonalizedMessage,
        ownerAccount,
        emailTemplate,
        setFormData,
        isValidationValid,
    ]);
    // methods
    const handleInvite = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        inviteClient(formData);
        setIsInviteDialogOpen(false);
        notifyToast({
            title: 'Invite Sent',
            description: 'Your client will receive an invite shortly in their email',
        });
    }), [formData, setIsInviteDialogOpen, inviteClient, notifyToast]);
    return (_jsx(LoadingTransition, { isLoading: isLoading, loading: _jsx(Loader, {}), content: _jsxs(_Fragment, { children: [_jsx(TablePage, { title: `${organization === null || organization === void 0 ? void 0 : organization.name} Invite Portal`, columns: columns, rows: accounts, actions: actions, isBackButton: isBackButton, toolbarActions: _jsx(Button, Object.assign({ ariaLabel: 'invite new clients', size: 'sm', onPress: () => setIsInviteDialogOpen(true), iconLeft: 'plus', isRectangle: true }, { children: "Invite client" })) }), _jsx(Dialog, Object.assign({ title: 'Invite Client', isCloseButton: true, isOpen: isInviteDialogOpen, setIsOpen: (isOpen) => setIsInviteDialogOpen(isOpen), variant: 'dialog', isNoPadding: true }, { children: _jsx(OnboardingFlow, { config: inviteFormConfig, alignActions: 'right', onComplete: () => {
                            handleInvite();
                        } }) }))] }) }));
};
ClientPortal.displayName = 'ClientPortal';
export default ClientPortal;
