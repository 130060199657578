var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// radix
import * as RadixToast from '@radix-ui/react-toast';
// components
import { Icon } from "../Icon/Icon";
/**
 * Toast provides a stylised notification.
 */
export const Toast = (_a) => {
    var { title, description, isOpen = false, setIsOpen, variant = 'plain', duration = 20000, isApple = false } = _a, props = __rest(_a, ["title", "description", "isOpen", "setIsOpen", "variant", "duration", "isApple"]);
    // methods
    const renderIcon = useCallback((variant) => {
        switch (variant) {
            case 'success':
                return _jsx(Icon, { icon: 'check', fontSize: 14 });
            case 'error':
                return _jsx(Icon, { icon: 'xmark', fontSize: 14 });
            case 'warning':
                return _jsx(Icon, { icon: 'exclamation-circle', fontSize: 14 });
            case 'info':
                return _jsx(Icon, { icon: 'info-circle', fontSize: 14 });
        }
    }, []);
    // memos
    const computedDescription = useMemo(() => {
        // scan description for markdown-style links and plain URLs
        const desc = [];
        // Updated regex to match markdown-style links with spaces in `[]` and any URL in `()`
        const regex = /\[([^\]]+)\]\(([^)]+)\)|https?:\/\/[^\s]+/g;
        let match;
        let lastIndex = 0;
        // Iterate over all matches
        while ((match = regex.exec(description)) !== null) {
            const plainText = description.slice(lastIndex, match.index);
            if (plainText) {
                desc.push(plainText);
            }
            if (match[1] && match[2]) {
                // If match is markdown-style link
                desc.push(_jsx("a", Object.assign({ href: match[2], target: '_blank', rel: 'noreferrer' }, { children: match[1] }), match.index));
            }
            else if (match[0]) {
                // If match is a plain URL
                desc.push(_jsx("a", Object.assign({ href: match[0], target: '_blank', rel: 'noreferrer' }, { children: match[0] }), match.index));
            }
            lastIndex = regex.lastIndex;
        }
        // Push any remaining text after the last match
        if (lastIndex < description.length) {
            desc.push(description.slice(lastIndex));
        }
        return desc;
    }, [description]);
    return (_jsxs(RadixToast.Provider, Object.assign({ swipeDirection: 'right', duration: duration }, { children: [_jsxs(StyledToastRoot, Object.assign({ open: isOpen, onOpenChange: setIsOpen, variant: variant }, { children: [_jsx(StyledToastCloseContainer, { children: _jsx(StyledToastClose, Object.assign({ "aria-label": 'Close' }, { children: _jsx("span", Object.assign({ "aria-hidden": true }, { children: _jsx(Icon, { icon: 'xmark', fontSize: 20 }) })) })) }), _jsxs(StyledContainer, { children: [variant !== 'plain' && (_jsx(StyledToastIcon, Object.assign({ variant: variant }, { children: renderIcon(variant) }))), _jsxs(StyledContentContainer, { children: [_jsx(StyledToastTitle, { children: title }), _jsx(StyledToastDescription, { children: computedDescription }), variant === 'error' && (_jsxs(ErrorMessage, { children: ["Please contact support at", ' ', _jsx("a", Object.assign({ href: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}` }, { children: process.env.REACT_APP_SUPPORT_EMAIL })), ' ', "if you continue to experience issues."] }))] })] })] })), _jsx(StyledToastViewport, { "$isApple": isApple })] })));
};
const StyledToastViewport = styled(RadixToast.Viewport) `
  // default styles
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  bottom: ${(props) => props.theme.spacing[64]};
  ${(props) => props.$isApple &&
    css `
      bottom: 85px;
    `}
  display: flex;
  padding: ${(props) => props.theme.spacing[24]};
  list-style: none;
  z-index: 2147483647;
  margin: 0;
  width: 400px;
  max-width: 100vw;
  outline: none;
  &:focus {
    outline: none;
  }

  ${(props) => props.theme.breakpoint.sm(css `
      bottom: 0;
    `)}
`;
const StyledToastRoot = styled(RadixToast.Root) `
  // default styles
  --viewport-padding: ${(props) => props.theme.spacing[20]};
  padding: var(--viewport-padding);
  padding-bottom: ${(props) => props.theme.spacing[32]};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  box-shadow: ${(props) => props.theme.elevation[4]};
  z-index: 2147483647;

  ${(props) => {
    switch (props.variant) {
        case 'plain':
            return css `
          background: ${props.theme.color.neutral[10]};
          color: ${props.theme.color.neutral[90]};
          border-color: ${props.theme.color.neutral[50]};
        `;
        case 'success':
            return css `
          background: ${props.theme.color.success[20]};
          color: ${props.theme.color.success[60]};
          border-color: ${props.theme.color.success[30]};
        `;
        case 'error':
            return css `
          background: ${props.theme.color.danger[20]};
          color: ${props.theme.color.danger[60]};
          border-color: ${props.theme.color.danger[30]};
        `;
        case 'warning':
            return css `
          background: ${props.theme.color.warning[20]};
          color: ${props.theme.color.warning[60]};
          border-color: ${props.theme.color.warning[30]};
        `;
        case 'info':
            return css `
          background: ${props.theme.color.info[10]};
          color: ${props.theme.color.info[50]};
          border-color: ${props.theme.color.info[30]};
        `;
    }
}}

  &[data-state='open'] {
    animation: slideIn 400ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state='closed'] {
    animation: hide 100ms ease-in;
  }
  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
  &[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
  }
  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes swipeOut {
    from {
      transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
  }
`;
const StyledToastTitle = styled(RadixToast.Title) `
  grid-area: title;
  font: ${(props) => props.theme.typography.body.lg.bold};
`;
const StyledToastDescription = styled(RadixToast.Description) `
  grid-area: description;
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const StyledToastIcon = styled.div `
  // default
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing[2]};
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  color: ${(props) => props.theme.color.neutral[10]};
  border-radius: 9999px;
  ${(props) => {
    switch (props.variant) {
        case 'success':
            return css `
          background: ${props.theme.color.success[40]};
        `;
        case 'error':
            return css `
          background: ${props.theme.color.danger[40]};
        `;
        case 'warning':
            return css `
          background: ${props.theme.color.warning[40]};
          overflow: hidden;
          & > svg {
            min-width: 28px;
            min-height: 28px;
            transform: rotate(180deg);
          }
        `;
        case 'info':
            return css `
          background: ${props.theme.color.info[40]};
          // TODO: remove this once we have custom icons
          overflow: hidden;
          & > svg {
            min-width: 28px;
            min-height: 28px;
          }
        `;
    }
}}
`;
const StyledContainer = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledContentContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledToastCloseContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: end;
`;
const StyledToastClose = styled(RadixToast.Close) `
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: -4px;
  color: ${(props) => props.theme.color.neutral[90]};
`;
const ErrorMessage = styled.div `
  font: ${(props) => props.theme.typography.body.xxs.regular};
  color: ${(props) => props.theme.color.danger[60]};
  padding-top: ${(props) => props.theme.spacing[8]};
`;
Toast.componentName = 'Toast';
export default Toast;
