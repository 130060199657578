import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Accordion, Container, Dialog, Icon, Label, } from "../../atoms";
import { validateIcon } from "../../atoms/Icon";
import { CareProfileHeader, FloatingActions, } from "../../molecules";
// theme
import { theme } from "../../../theme";
/**
 * CareProfileRoot introduces the care plan.
 */
export const CareProfileRoot = ({ className, children, backLabel = 'Back', nextLabel = 'Next', onPrintPdf, limit, progress, sections, title, name, toExit, onNext, toNext, toPrevious, isFormCompleted = false, }) => {
    // state
    const [isSectionsOpen, setIsSectionsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsx(StyledCareProfileHeader, { limit: limit, progress: progress, toBack: toExit, onPrintPdf: onPrintPdf, name: name, title: title, onOpenSections: () => setIsSectionsOpen(true) }), _jsx(StyledContainer, Object.assign({ size: 'md' }, { children: children }))] })), _jsxs(Dialog, Object.assign({ variant: 'sheet', isOpen: isSectionsOpen, setIsOpen: setIsSectionsOpen, direction: 'right' }, { children: [_jsx(JumpToSectionTitle, { children: "Jump to a section" }), _jsx(SectionWrapper, { children: _jsx(Accordion, { items: sections === null || sections === void 0 ? void 0 : sections.map((section) => {
                                var _a;
                                const colorAndIconMap = {
                                    complete: {
                                        primary: theme.color.success[10],
                                        secondary: theme.color.success[40],
                                        icon: 'check',
                                    },
                                    inProgress: {
                                        primary: theme.color.warning[10],
                                        secondary: theme.color.warning[40],
                                        icon: 'minus',
                                    },
                                    notStarted: {
                                        primary: theme.color.neutral[10],
                                        secondary: theme.color.neutral[40],
                                        icon: 'check',
                                    },
                                };
                                const item = {
                                    id: section.id,
                                    label: section.title,
                                    content: (_jsx(_Fragment, { children: (_a = section.subSections) === null || _a === void 0 ? void 0 : _a.map((subsec) => (_jsxs(SectionItem, Object.assign({ to: subsec.to ? subsec.to : '', isActive: location.pathname === subsec.to }, { children: [_jsx(StyledIconContainer, Object.assign({ isCompleted: subsec.isComplete }, { children: _jsx(Icon, { icon: 'check' }) })), _jsx(StyedLabel, { id: subsec.id, text: subsec.title })] }), subsec.id))) })),
                                    icon: validateIcon(colorAndIconMap[section.sectionStatus].icon),
                                    primaryColor: colorAndIconMap[section.sectionStatus].primary,
                                    secondaryColor: colorAndIconMap[section.sectionStatus].secondary,
                                };
                                return item;
                            }) }) })] })), _jsx(FloatingActions, { isSticky: true, direction: toPrevious ? 'row' : 'col', actions: [
                    ...(toPrevious
                        ? [
                            {
                                label: backLabel,
                                variant: 'secondary',
                                to: toPrevious,
                            },
                        ]
                        : []),
                    {
                        label: nextLabel,
                        variant: 'primary',
                        onPress: onNext,
                        to: toNext,
                        iconRight: 'chevron-right',
                        isDisabled: isFormCompleted === false,
                    },
                ] })] }));
};
const StyledIconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[4]};
  border-radius: 99999px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  background-color: ${(props) => props.isCompleted
    ? props.theme.color.success[40]
    : props.theme.color.neutral[40]};
  color: white;
  margin-right: ${(props) => props.theme.spacing[8]};
`;
const Wrapper = styled.div `
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
const StyledContainer = styled(Container) `
  flex: 1;
  overflow-y: auto;
`;
const StyledCareProfileHeader = styled(CareProfileHeader) `
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`;
const JumpToSectionTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[80]};
  margin-bottom: ${(props) => props.theme.spacing[16]};
`;
const SectionWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  overflow-y: auto;
  max-height: 100%;
`;
const SectionItem = styled(Link) `
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  margin-left: ${(props) => props.theme.spacing[16]};
  padding-left: ${(props) => props.theme.spacing[16]};
  transition-duration: 0.2s;
  background: ${(props) => props.isActive ? props.theme.color.neutral[30] : 'transparent'};
  &:hover {
    background: ${(props) => props.theme.color.neutral[30]};
  }
`;
const StyedLabel = styled(Label) `
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[12]} 0;
`;
CareProfileRoot.displayName = 'CareProfileRoot';
export default CareProfileRoot;
