import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
/**
 * CareProfileComplete tells the user that they have completed their care plan.
 */
export const CareProfileComplete = ({ className, email, isComplete, toBookCall, }) => {
    return (_jsxs(Wrapper, { children: [_jsx(P, { children: "Our Care Concierge team are on hand to talk through your Care Assessment. Whether you need help answering any of the questions, or you're ready to share this with care providers and other professionals and want advice on the best process forwards, get in touch with us now." }), _jsx(Button, Object.assign({ ariaLabel: 'book a call back', to: toBookCall }, { children: "Book a call back" }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Body = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const OL = styled.ol `
  padding-left: ${(props) => props.theme.spacing[24]};
  margin: ${(props) => props.theme.spacing[8]};
`;
const LI = styled.li `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  margin-bottom: ${(props) => props.theme.spacing[12]};
`;
const P = styled.p `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  margin: 0;
  margin-top: ${(props) => props.theme.spacing[32]};
  text-align: center;
`;
const StyledLink = styled.a `
  color: ${(props) => props.theme.color.primary[80]};
`;
CareProfileComplete.displayName = 'CareProfileComplete';
export default CareProfileComplete;
